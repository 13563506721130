import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PREFIX } from '@innogy/config';
import { map } from 'rxjs/operators';

import type { ReviewsInfo } from '../../interfaces';

@Injectable()
export class ReviewInfoService implements BaseService<void, ReviewsInfo> {
  readonly apiVersion = '';
  readonly endpoint = `${this.apiPrefix}/energiewonen/review-info/`;

  constructor(
    private readonly http: HttpClient,
    @Inject(API_PREFIX) private readonly apiPrefix: string
  ) {}

  public call$() {
    return this.http
      .get<ReviewsInfo>(this.endpoint)
      .pipe(
        map((reviewsInfoResponse) => this.mapResponse(reviewsInfoResponse))
      );
  }

  private mapResponse(response: ReviewsInfo): ReviewsInfo {
    return {
      average: response.average,
      reviews: response.reviews,
      percentage_recommendation: response.percentage_recommendation,
    };
  }
}

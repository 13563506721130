import type { OnInit } from '@angular/core';
import { Input, Component } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'ep-klanten-vertellen',
  templateUrl: './klanten-vertellen.component.html',
  styleUrls: ['./klanten-vertellen.component.energiewonen.scss'],
})
export class KlantenVertellenComponent implements OnInit {
  @Input() compact?: boolean;
  @Input() whiteText?: boolean;
  @Input() score?: number;
  @Input() reviews?: number;
  @Input() url?: string;
  @Input() reviewsViaText?: string;

  starsArray: IconProp[] = [];

  ngOnInit() {
    this.starsArray = this.score ? this.getStarIcons(this.score) : [];
  }

  getStarIcons(score: number): IconProp[] {
    const stars = Math.round(score) / 2; // klantenvertellen's way to determine stars

    const icons = new Array(Math.floor(stars)).fill(['fas', 'star']);

    // add half star at end if the amount of stars contains .5
    if (stars % 1 !== 0) {
      icons.push(['fas', 'star-half']);
    }
    return icons;
  }
}

import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { getFieldValue } from '@innogy/jss/utils';
import { InnogyComponentRendering } from '@innogy/jss/models';
import { Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import type { ReviewsInfo } from '@innogy/eplus-partners/reviews/store';
import { getReviewInfoData } from '@innogy/eplus-partners/reviews/store';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'ep-klanten-vertellen-container',
  templateUrl: './klanten-vertellen-container.component.html',
  styleUrls: [],
})
export class KlantenVertellenContainerComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;

  reviewInfo$?: Observable<ReviewsInfo | undefined>;
  compact?: boolean;
  whiteText?: boolean;
  url?: string;
  reviewsViaText?: string;

  constructor(private readonly store$: Store<any>) {}

  ngOnInit(): void {
    this.reviewInfo$ = this.store$.select(getReviewInfoData);
    this.compact = getFieldValue<boolean>(this.rendering, 'compact');
    this.whiteText = getFieldValue<boolean>(this.rendering, 'whiteText');
    this.url = getFieldValue<any>(this.rendering, 'url')?.href;
    this.reviewsViaText = getFieldValue<string>(
      this.rendering,
      'reviewsViaText'
    );
  }

  isInExperienceEditor() {
    return isEditorActive();
  }
}

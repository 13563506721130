import type { ValidationErrors } from 'ngrx-forms';

export const phoneNumberAltRegex = new RegExp('^[0][0-9]{9}$');

export interface IsPhoneNumberAltValidator {
  actual: any;
}

declare module 'ngrx-forms/src/state' {
  export interface ValidationErrors {
    isPhoneNumber?: IsPhoneNumberAltValidator;
  }
}

const _IsPhoneNumberAlt = (value?: string) => {
  if (value == null) {
    return false;
  }

  const home =
    /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
  const mobile = /^(((\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;
  return home.test(value) || mobile.test(value);
};

/**
 * Validates that the given value is an PhoneNumber
 * @param value
 */
export const isPhoneNumberAlt = (value: string): ValidationErrors => {
  if (_IsPhoneNumberAlt(value)) {
    return {};
  }

  return {
    isPhoneNumber: { actual: value },
  };
};

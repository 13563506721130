import { createBaseAction } from '@essent/common';
import type { HttpErrorResponse } from '@angular/common/http';

import type {
  CreateReviewPayload,
  CreateReviewResponse,
} from '../../interfaces';

export enum createReviewActionTypes {
  CREATE = '[Reviews] Create review',
  SUCCESS = '[Reviews] Create review success',
  ERROR = '[Reviews] Create review error',
  CLEAR = '[Reviews] Create review clear',
}

export const createReview = createBaseAction<CreateReviewPayload>(
  createReviewActionTypes.CREATE
);
export const createReviewSuccess = createBaseAction<CreateReviewResponse>(
  createReviewActionTypes.SUCCESS
);
export const createReviewError = createBaseAction<HttpErrorResponse>(
  createReviewActionTypes.ERROR
);
export const createReviewClear = createBaseAction<void>(
  createReviewActionTypes.CLEAR
);

import { createBaseAction } from '@essent/common';
import type { HttpErrorResponse } from '@angular/common/http';

import type { CustomerSupportFormValues } from '../customer-support-form/customer-support-form.reducer';

export enum createCustomerSupportRequestActionTypes {
  CREATE = '[Create customer support request] Create',
  SUCCESS = '[Create customer support request] Success',
  ERROR = '[Create customer support request] Error',
  CLEAR = '[Create customer support request] Clear',
}

export interface CustomerSupportformRequest {
  form: CustomerSupportFormValues;
  images: File[];
}

export const createCustomerSupportRequest =
  createBaseAction<CustomerSupportformRequest>(
    createCustomerSupportRequestActionTypes.CREATE
  );
export const createCustomerSupportRequestSuccess = createBaseAction<void>(
  createCustomerSupportRequestActionTypes.SUCCESS
);
export const createCustomerSupportRequestError =
  createBaseAction<HttpErrorResponse>(
    createCustomerSupportRequestActionTypes.ERROR
  );
export const createCustomerSupportRequestClear = createBaseAction<void>(
  createCustomerSupportRequestActionTypes.CLEAR
);

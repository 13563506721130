import { Component, Input } from '@angular/core';

export interface Image {
  src: string;
  alt: string;
}

@Component({
  selector: 'ep-image-card',
  templateUrl: './image-card.component.html',
})
export class ImageCardComponent {
  @Input() image: Image = {
    src: 'assets/placeholder-image.png',
    alt: 'Placeholder Image',
  };
}

<div class="article-header__background">
  <ng-container *ngIf="data?.fields?.backgroundImage?.value">
    <img
      *scImage="data?.fields?.backgroundImage"
      class="article-header__background-image"
      alt=""
    />
  </ng-container>
  <div class="article-header__container container">
    <div class="row">
      <div class="col">
        <div class="col offset-lg-2 col-lg-8">
          <div class="pt-3 pt-md-4 pt-lg-8">
            <div *ngIf="data?.fields?.title.value">
              <h1
                *scText="data?.fields?.title"
                class="article-header__title text-center"
              ></h1>
            </div>
            <div *ngIf="data?.fields?.subtitle.value">
              <p
                *scText="data?.fields?.subtitle"
                class="article-header__subtitle intro text-center"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="article__background pb-4 pb-md-5 pb-lg-8">
  <div class="article__container container">
    <img
      *scImage="data?.fields?.imageUrl"
      class="article__image w-100"
      loading="lazy"
    />
    <div class="article__content bg-white pt-5 pt-lg-8">
      <div class="offset-lg-2 col-lg-8 px-0">
        <sc-placeholder
          name="ew-article-components"
          [rendering]="rendering"
        ></sc-placeholder>
      </div>
    </div>
  </div>
  <div *ngIf="data?.fields?.footnote.value" class="container">
    <div class="row">
      <div class="col">
        <div class="article-footnote__background py-4 py-md-5 py-lg-8">
          <div class="col offset-lg-2 col-lg-8">
            <wl-rich-text-wrapper [field]="data?.fields?.footnote">
              <div class="article-footnote"></div>
            </wl-rich-text-wrapper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <sc-placeholder name="ew-entry" [rendering]="rendering"></sc-placeholder>
</div>

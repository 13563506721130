<div
  class="product p-2"
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
>
  <div class="d-flex align-items-center justify-content-center mb-4">
    <img
      *scImage="fields?.image?.value | imagePlaceholder"
      class="product__image"
      loading="lazy"
    />
  </div>
  <div>
    <h3 class="mb-0 text-dark" *scText="fields?.title"></h3>
    <p class="mb-4" *scText="fields?.description"></p>
  </div>
  <div>
    <h4 class="mb-0">
      <span *scText="fields?.titleUsp1" class="text-dark-night"></span>
      <ep-tooltip class="text-secondary ms-1">
        <span *scText="fields?.tooltipUsp1"></span>
      </ep-tooltip>
    </h4>
    <p class="mb-4 text-secondary" *scText="fields?.descriptionUsp1"></p>
  </div>
  <div>
    <h4 class="mb-0">
      <span *scText="fields?.titleUsp2" class="text-dark-night"></span>
      <ep-tooltip class="text-secondary ms-1">
        <span *scText="fields?.tooltipUsp2"></span>
      </ep-tooltip>
    </h4>
    <p class="mb-4 text-secondary" *scText="fields?.descriptionUsp2"></p>
  </div>
  <div>
    <h4 class="mb-0">
      <span *scText="fields?.titleUsp3" class="text-dark-night"></span>
      <ep-tooltip class="text-secondary ms-1">
        <span *scText="fields?.tooltipUsp3"></span>
      </ep-tooltip>
    </h4>
    <p class="mb-4 text-secondary" *scText="fields?.descriptionUsp3"></p>
  </div>
  <div class="mb-2">
    <a *wlGenericLink="fields?.link"></a>
  </div>
</div>

import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';
import {
  required,
  greaterThanOrEqualTo,
  lessThanOrEqualTo,
  maxLength,
} from 'ngrx-forms/validation';
import {
  isEmailAddress,
  isNumeric,
  isPostalCode,
  validateSequential,
} from '@innogy/utils/deprecated';
import type { FormGroupState, Boxed } from 'ngrx-forms';
import {
  createFormGroupState,
  updateGroup,
  onNgrxForms,
  wrapReducerWithFormStateUpdate,
  box,
} from 'ngrx-forms';

import { resetCustomerSupportFormState } from './customer-support-form.actions';

export const CUSTOMER_SUPPORT_FORM_ID = 'customerSupportForm';
export const emailControlId = `${CUSTOMER_SUPPORT_FORM_ID}.email`;
export const postalCodeControlId = `${CUSTOMER_SUPPORT_FORM_ID}.postalCode`;
export const houseNumberControlId = `${CUSTOMER_SUPPORT_FORM_ID}.houseNumber`;
export const houseNumberAdditionControlId = `${CUSTOMER_SUPPORT_FORM_ID}.houseNumberAddition`;
export const subjectControlId = `${CUSTOMER_SUPPORT_FORM_ID}.subjectControlId`;
export const systemControlId = `${CUSTOMER_SUPPORT_FORM_ID}.systemControlId`;
export const questionControlId = `${CUSTOMER_SUPPORT_FORM_ID}.questionControlId`;
export const photosControlId = `${CUSTOMER_SUPPORT_FORM_ID}.photosControlId`;

export interface CustomerSupportFormValues {
  email: string;
  postalCode: string;
  houseNumber: number | undefined;
  houseNumberAddition: string;
  subject: string;
  system: string;
  question: string;
  photos: Boxed<string[]>;
}

export type CustomerSupportFormState =
  FormGroupState<CustomerSupportFormValues>;

export const initialFormState: CustomerSupportFormValues = {
  email: '',
  postalCode: '',
  houseNumber: undefined,
  houseNumberAddition: '',
  subject: '',
  system: '',
  question: '',
  photos: box([]),
};

export const initialState = createFormGroupState<CustomerSupportFormValues>(
  CUSTOMER_SUPPORT_FORM_ID,
  initialFormState
);

export const validateAndUpdateForms = (state: CustomerSupportFormState) => {
  return updateGroup<CustomerSupportFormValues>({
    email: validateSequential(required, isEmailAddress),
    postalCode: validateSequential(required, isPostalCode),
    houseNumber: validateSequential(
      required,
      isNumeric,
      greaterThanOrEqualTo(1),
      lessThanOrEqualTo(99999)
    ),
    subject: validateSequential(required),
    system: validateSequential(required),
    question: validateSequential(required),
    photos: validateSequential(maxLength(3)),
  })(state);
};

const _reducer = createReducer(
  initialState,
  onNgrxForms(),
  on(resetCustomerSupportFormState, () => initialState)
);

const wrappedReducer = wrapReducerWithFormStateUpdate(
  _reducer,
  (state: CustomerSupportFormState) => state,
  (_, state) => validateAndUpdateForms(state)
);

export function customerSupportFormReducer(
  state: CustomerSupportFormState = initialState,
  action: Action
): CustomerSupportFormState {
  return wrappedReducer(state, action);
}

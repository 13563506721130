import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';
import type { Action } from '@ngrx/store';

import type { PaginatedReviews } from '../../interfaces';
import * as actions from '../actions/get-reviews.actions';

export type ReviewsState = AsyncState<PaginatedReviews>;

const initalState: ReviewsState = { status: Status.IDLE };
const _getReviewsReducer = createAsyncStateReducer(
  actions.getReviews,
  actions.getReviewsSuccess,
  actions.getReviewsError,
  actions.getReviewsClear
);

export function GetReviewsReducer(
  state: ReviewsState = initalState,
  action: Action
): ReviewsState {
  return _getReviewsReducer(state, action);
}

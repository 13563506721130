import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';
import type { Action } from '@ngrx/store';

import {
  UpdatePaymentStatusAction,
  UpdatePaymentStatusClearAction,
  UpdatePaymentStatusErrorAction,
  UpdatePaymentStatusSuccessAction,
} from './update-status.actions';
import type { UpdateStatusResponse } from './update-status.interface';

export type UpdateStatusState = AsyncState<UpdateStatusResponse>;

const _updateStatus = createAsyncStateReducer(
  UpdatePaymentStatusAction,
  UpdatePaymentStatusSuccessAction,
  UpdatePaymentStatusErrorAction,
  UpdatePaymentStatusClearAction
);

export function createUpdateStatusReducer(
  state: UpdateStatusState = { status: Status.IDLE },
  action: Action
): UpdateStatusState {
  return _updateStatus(state, action);
}

import { NgModule } from '@angular/core';
import { EplusUiOnlyModule } from '@innogy/eplus-partners/ui-only';
import { DefaultComponentModule } from '@innogy/core/modules/components';

import { SingleColumnRowComponent } from './single-column-row/single-column-row.component';
import { TwoColumnRowComponent } from './two-column-row/two-column-row.component';
import { ThreeColumnRowComponent } from './three-column-row/three-column-row.component';
import { ColumnComponent } from './column/column.component';
import { RowTitleComponent } from './title/title.component';

@NgModule({
  imports: [DefaultComponentModule, EplusUiOnlyModule],
  declarations: [
    RowTitleComponent,
    ColumnComponent,
    SingleColumnRowComponent,
    TwoColumnRowComponent,
    ThreeColumnRowComponent,
  ],
})
export class EplusGridModule {}

import { mockBaseEPlusWizardRendering } from '@innogy/eplus/shared';
import {
  mockCheckboxField,
  mockGroupedDroplink,
  mockLinkField,
  mockRadioList,
  mockRichTextField,
  mockTextField,
} from '@innogy/jss';

import type { SolarPanelsOrderFormRendering } from '../models/solar-panels-order-form-rendering.interface';
import type { SolarPanelsProductOverviewRendering } from '../models/solar-panels-product-overview-rendering.interface';
import type { SolarPanelsReceiptRendering } from '../models/solar-panels-receipt-rendering.interface';
import type { SolarPanelsResultOverviewRendering } from '../models/solar-panels-result-overview-rendering.interface';
import type { SolarPanelsResultOverviewSummaryRendering } from '../models/solar-panels-result-overview-summary-rendering.interface';
import type { SolarPanelsSuitabilityCheckRendering } from '../models/solar-panels-suitability-check-rendering.interface';
import type { SolarPanelsWizardRendering } from '../models/solar-panels-wizard-rendering.interface';

export const mockSolarPanelsWizardRendering: SolarPanelsWizardRendering = {
  componentName: 'goat-SolarPanelsWizard',
  fields: {
    ...mockBaseEPlusWizardRendering.fields,
    componentName: {
      value: 'goat-SolarPanelsWizard-componentName',
    },
    FunnelSettings: mockTextField('goat-FunnelSettings'),
    ElectricityUsagePlaceholder: mockTextField(
      'goat-ElectricityUsagePlaceholder'
    ),
    ElectricityUsageLabel: mockTextField('goat-ElectricityUsageLabel'),
    ElectricityUsageTooltip: mockTextField('goat-ElectricityUsageTooltip'),
    ElectricityEstimateButton: mockTextField('goat-ElectricityEstimateButton'),
  },
} as SolarPanelsWizardRendering;

export const mockSolarPanelsProductOverviewRendering: SolarPanelsProductOverviewRendering =
  {
    componentName: 'goat-SolarPanelsProductOverview',
    fields: {
      componentName: {
        value: 'goat-SolarPanelsProductOverview-componentName',
      },
      FunnelSettings: mockTextField('goat-FunnelSettings'),
      SaveLabelLeft: mockTextField('goat-SaveLabelLeft'),
      SaveLabelRight: mockTextField('goat-SaveLabelRight'),
      YieldLabel: mockTextField('goat-YieldLabel'),
      YieldIcon: mockGroupedDroplink('goat-YieldIcon'),
      PerformanceLabel: mockTextField('goat-PerformanceLabel'),
      PerformanceIcon: mockGroupedDroplink('goat-PerformanceIcon'),
      PerformanceGuaranteedLabel: mockTextField(
        'goat-PerformanceGuaranteedLabel'
      ),
      PerformanceGuaranteedIcon: mockGroupedDroplink(
        'goat-PerformanceGuaranteedIcon'
      ),
      ChooseProductButtonText: mockTextField('goat-ChooseProductButtonText'),
    },
  } as SolarPanelsProductOverviewRendering;

export const mockSolarSolarPanelSuitabilityCheckRendering: SolarPanelsSuitabilityCheckRendering =
  {
    componentName: 'goat-SolarPanelSuitabilityCheck',
    fields: {
      componentName: {
        value: 'goat-SolarPanelSuitabilityCheck-componentName',
      },
      FunnelSettings: mockTextField('goat-FunnelSettings'),
      AsbestosRadios: mockRadioList([
        'AsbestosRadios-yes',
        'AsbestosRadios-no',
      ]),
      RoofTypeRadios: mockRadioList([
        'RoofTypeRadios-yes',
        'RoofTypeRadios-no',
      ]),
      RoofReplacedRadios: mockRadioList([
        'RoofReplacedRadios-yes',
        'RoofReplacedRadios-no',
      ]),
      AsbestosLabel: mockTextField('goat-AsbestosLabel'),
      RoofTypeLabel: mockTextField('goat-RoofTypeLabel'),
      RoofReplacedLabel: mockTextField('goat-RoofReplacedLabel'),
      SubmitButtonText: mockTextField('goat-SubmitButtonText'),
      UnsuitableHouseMessage: mockTextField('goat-UnsuitableHouseMessage'),
    },
  } as SolarPanelsSuitabilityCheckRendering;

export const mockViewModel: any = {
  addressCheck: {
    hasErrored: false,
  },
  formState: {
    controls: {},
  },
};

export const mockSolarReceiptRendering: SolarPanelsReceiptRendering = {
  componentName: 'goat-SolarReceipt',
  fields: {
    componentName: {
      value: 'goat-SolarReceipt-componentName',
    },
    Title: mockTextField('goat-Title'),
    AmountAndTypeOfPanelsLabel: mockTextField(
      'goat-AmountAndTypeOfPanelsLabel'
    ),
    AmountAndTypeOfPanelsValue: mockTextField(
      'goat-AmountAndTypeOfPanelsValue'
    ),
    ProductGuaranteeLabel: mockTextField('goat-ProductGuaranteeLabel'),
    ProductGuaranteeValue: mockTextField('goat-ProductGuaranteeValue'),
    PowerGuaranteeLabel: mockTextField('goat-PowerGuaranteeLabel'),
    PowerGuaranteeValue: mockTextField('goat-PowerGuaranteeValue'),
    EstimatedYearlyRevenueLabel: mockTextField(
      'goat-EstimatedYearlyRevenueLabel'
    ),
    EstimatedYearlyRevenueValue: mockTextField(
      'goat-EstimatedYearlyRevenueValue'
    ),
    SavingsPerYearLabel: mockTextField('goat-SavingsPerYearLabel'),
    SavingsPerYearTooltip: mockTextField('goat-SavingsPerYearTooltip'),
  },
} as SolarPanelsReceiptRendering;

export const mockSolarResultOverviewRendering: SolarPanelsResultOverviewRendering =
  {
    componentName: 'goat-ResultOverview',
    fields: {
      componentName: {
        value: 'goat-ResultOverview-componentName',
      },
      FunnelSettings: mockTextField('goat-FunnelSettings'),
      Title: mockTextField('goat-Title'),
      Usps: mockRichTextField('goat-<b>USP</b>'),
      NextStepButtonText: mockTextField('goat-NextStepButton'),
      MoreInfoButtonText: mockTextField('goat-MoreInfoButton'),
      MoreInfoDescriptionText: mockRichTextField('goat-MoreInfoDescription'),
      SecondaryButtonLink: mockLinkField('goat-SecondaryButton'),
    },
  } as SolarPanelsResultOverviewRendering;

export const mockSolarResultOverviewSummaryRendering: SolarPanelsResultOverviewSummaryRendering =
  {
    componentName: 'goat-ResultOverviewSummary',
    fields: {
      componentName: {
        value: 'goat-ResultOverviewSummary-componentName',
      },
      FunnelSettings: mockTextField('goat-FunnelSettings'),
      SummaryTitle: mockTextField('goat-SummaryTitle'),
      MaximumAmountOfPanels: mockRichTextField(
        'goat-MaximumAmountOfPanels-{{maxPanels|?}}'
      ),
      MaximumAmountOfPanelsTooltip: mockTextField(
        'goat-MaximumAmountOfPanelsTooltip'
      ),
      RecommendedAmountOfPanels: mockRichTextField(
        'goat-RecommendedAmountOfPanels-{{recommendedPanels|?}}'
      ),
      RecommendedAmountOfPanelsTooltip: mockTextField(
        'goat-RecommendedAmountOfPanelsTooltip'
      ),
      SavingsPerYear: mockRichTextField(
        'goat-SavingsPerYear-{{savingsPerYear|?}}'
      ),
      SavingsPerYearTooltip: mockTextField('goat-SavingsPerYearTooltip'),
      IsPlacedOnOrderPage: mockCheckboxField(true),
    },
  } as SolarPanelsResultOverviewSummaryRendering;

export const mockSolarPanelsOrderFormRendering: SolarPanelsOrderFormRendering =
  {
    componentName: 'goat-OrderForm',
    fields: {
      componentName: {
        value: 'goat-OrderForm-componentName',
      },
      FunnelSettings: mockTextField('goat-FunnelSettings'),
    },
  } as SolarPanelsOrderFormRendering;

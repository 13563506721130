import { createAction, props } from '@ngrx/store';

export const clearMoreInformationForm = createAction(
  '[Send More Information] Clear form'
);

export const validateMoreInformationForm = createAction(
  '[Send More Information] validate form'
);

export const submitMoreInformationForm = createAction(
  '[Send More Information] Submit',
  props<{
    email: string;
    mailingList: string;
    approveTermsOfService: boolean;
  }>()
);

import { createSelector } from '@ngrx/store';

import { getCustomerSupportFormState } from '../customer-support-form/customer-support-form.selectors';
import { getCreateCustomerSupportRequestState } from '../create-customer-support-request/create-customer-support-request.selectors';
import { getCustomerSupportSelector } from '../reducers';

export const selectCustomerSupportComponentState = createSelector(
  getCustomerSupportSelector,
  (state) => state.customerSupportComponent
);

export const selectCustomerSupportComponentVm = createSelector(
  selectCustomerSupportComponentState,
  getCustomerSupportFormState,
  getCreateCustomerSupportRequestState,
  (customerSupportState, customerSupportForm, createCustomerSupportRequest) => {
    return {
      customerSupport: customerSupportState,
      createCustomerSupportRequestStatus: createCustomerSupportRequest.status,
      form: customerSupportForm,
      values: {
        email: customerSupportForm.value.email,
        postalCode: customerSupportForm.value.postalCode,
        houseNumber: `${customerSupportForm.value.houseNumber} ${customerSupportForm.value.houseNumberAddition}`,
        subject: customerSupportForm.value.subject,
        system: customerSupportForm.value.system,
        question: customerSupportForm.value.question,
        photos: customerSupportForm.value.photos,
      },
    };
  }
);

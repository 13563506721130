import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./eplus-notification-banner.lazy.module').then(
    (mod) => mod.EplusNotificationBannerLazyModule
  );

const eplusNotificationBannerLazyImports: ComponentNameAndModule[] = [
  {
    path: 'NotificationBanner',
    loadChildren: loadChildrenFunc,
  },
];
export { eplusNotificationBannerLazyImports };

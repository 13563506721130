import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonUiUtilityComponentsModule } from '@innogy/common-ui/utility-components';
import { UtilsModule } from '@innogy/utils/deprecated';
import { UtilsContentsquareModule } from '@innogy/utils/contentsquare';
import {
  NgbAccordionModule,
  NgbCarouselModule,
} from '@ng-bootstrap/ng-bootstrap';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { CoreAnalyticsModule } from '@innogy/core/analytics';

import { GenericOfferBreakdownComponent } from './generic-offer-breakdown/generic-offer-breakdown.component';
import { GenericOfferCarouselComponent } from './generic-offer-carousel/generic-offer-carousel.component';
import { GenericOfferContainerComponent } from './generic-offer-container/generic-offer-container.component';
import { GenericOfferInfoComponent } from './generic-offer-info/generic-offer-info.component';
import { GenericOfferComponent } from './generic-offer/generic-offer.component';
import { GenericPriceInfoComponent } from './generic-price-info/generic-price-info.component';
import { GenericPricingBreakdownComponent } from './generic-pricing-breakdown/generic-pricing-breakdown.component';
import { GenericRatingComponent } from './generic-rating/generic-rating.component';
import { GenericUspsComponent } from './generic-usps/generic-usps.component';
import {
  PriceGroupAccordionComponent,
  PriceGroupAccordionContentDirective,
  PriceGroupAccordionHeaderDirective,
} from './price-group-accordion/price-group-accordion.component';
import { ScGenericOfferContainerComponent } from './sitecore';
import { GenericOfferCarouselIndicatorComponent } from './generic-offer-carousel-indicator/generic-offer-carousel-indicator.component';

const components = [
  GenericOfferComponent,
  GenericRatingComponent,
  GenericOfferContainerComponent,
  GenericUspsComponent,
  GenericOfferBreakdownComponent,
  GenericOfferCarouselComponent,
  GenericOfferCarouselIndicatorComponent,
  GenericPriceInfoComponent,
  GenericOfferInfoComponent,
  GenericPricingBreakdownComponent,
  PriceGroupAccordionComponent,
  PriceGroupAccordionHeaderDirective,
  PriceGroupAccordionContentDirective,
];

@NgModule({
  imports: [
    CommonModule,
    JssModule,
    FontAwesomeModule,
    NgbCarouselModule,
    NgbAccordionModule,
    CommonUiUtilityComponentsModule,
    UtilsModule,
    UtilsContentsquareModule,
    CoreAnalyticsModule,
  ],
  declarations: [...components, ScGenericOfferContainerComponent],
  exports: components,
})
export class CommonUiProductsModule {}

<ng-container *ngIf="isInExperienceEditor(); else klantenVertellen">
  <span><strong>Klantenvertellen widget</strong></span>
</ng-container>

<ng-template #klantenVertellen>
  <ng-container *ngIf="reviewInfo$ | async as reviewInfo">
    <ep-klanten-vertellen
      wlTrackImpression="contentblock-impression"
      [trackImpressionInfo]="rendering"
      [score]="reviewInfo?.average"
      [reviews]="reviewInfo?.reviews"
      [compact]="compact"
      [whiteText]="whiteText"
      [url]="url"
      [reviewsViaText]="reviewsViaText"
    ></ep-klanten-vertellen>
  </ng-container>
</ng-template>

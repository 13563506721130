<ul
  role="menubar"
  class="navbar-nav list-no-default-spacing"
  [class.flex-column]="isVertical"
>
  <ng-container *ngFor="let navItem of navigationItems; trackBy: trackByItems">
    <ng-container *ngIf="getRouteUrl(navItem, language) as route">
      <li class="nav-item" role="none">
        <a
          wlTrackLink
          [trackLinkInfo]="{
            rendering: { componentName: { value: 'header' } },
            field: navItem.name,
            eventType: 'navigation-click'
          }"
          *wlGenericLink="route"
          role="menuitem"
          class="nav-link"
          [class.active]="route | isActiveLink"
          >{{ navItem.name }}
        </a>
      </li>
    </ng-container>
  </ng-container>
</ul>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.ew.scss'],
})
export class RatingComponent {
  @Input() rate = 5.0;
  @Input() max = 5.0;
  @Input() size = '1rem';
}

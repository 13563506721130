import { NgModule } from '@angular/core';
import { EplusIconModule } from '@innogy/icons';
import { StructuredDataModule } from '@innogy/shared/structured-data';
import { DefaultComponentModule } from '@innogy/core/modules/components';

import { FaqCategoryComponent } from './faq-category/faq-category.component';
import { FaqContainerComponent } from './faq-container/faq-container.component';

@NgModule({
  imports: [DefaultComponentModule, EplusIconModule, StructuredDataModule],
  declarations: [FaqContainerComponent, FaqCategoryComponent],
  exports: [FaqContainerComponent],
})
export class EplusFaqModule {}

import { createNamedApi } from '@essent/common';
import { createAction } from '@ngrx/store';

import { GetIssuersService } from './get-issuers.service';

export const getIssuersApi = createNamedApi(
  '[Payment] Get Issuers',
  GetIssuersService
);

export const loadIssuersAction = createAction(
  '[Payment Get Issuers] Initiate load issuers'
);

export const {
  getIssuers: getIssuersAction,
  getIssuersSuccess: getIssuersSuccessAction,
  getIssuersError: getIssuersErrorAction,
  getIssuersClear: getIssuersClearAction,
} = getIssuersApi;

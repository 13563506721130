<ng-container *ngIf="vm$ | async as vm">
  <div class="bg-white header__wrapper">
    <div class="container">
      <div class="row">
        <div class="col">
          <header>
            <nav class="navbar navbar-expand navbar-light p-0 py-1 py-xl-2">
              <a
                [routerLink]="['/']"
                wlTrackLink
                [trackLinkInfo]="{
                  rendering: { componentName: { value: 'header' } },
                  field: 'Home logo button',
                  eventType: 'button-click'
                }"
              >
                <img
                  [src]="'assets/logo.svg?v=2' | deployUrl"
                  class="navbar-brand brand-logo"
                  alt="logo"
                />
              </a>

              <div
                *ngIf="vm.isMainNavigationAvailable"
                class="d-none d-xl-block"
                wlAutomationId="header-main-menu"
              >
                <ep-navigation
                  [navigationItems]="vm.mainNavigationItems"
                  [language]="vm.language"
                >
                </ep-navigation>
              </div>

              <ul
                *ngIf="vm.isLoaded"
                class="nav ms-auto d-flex flex-row justify-content-end align-items-center list-no-default-spacing"
                wlAutomationId="header-secondary-buttons"
              >
                <li
                  class="nav-item d-none d-md-block text-end"
                  [ngClass]="{ 'd-sm-block': vm.minimalNavigation }"
                >
                  <a
                    href="tel:{{ 'fullPhoneNumber' | translate }}"
                    class="nav-link navbar__telephone p-0"
                    wlTrackLink
                    [trackLinkInfo]="{
                      rendering: { componentName: { value: 'header' } },
                      field: 'userFriendlyPhoneNumber' | translate,
                      eventType: 'button-click'
                    }"
                  >
                    <fa-icon size="sm" [icon]="['fas', 'phone-flip']"></fa-icon>
                    {{ 'userFriendlyPhoneNumber' | translate }}
                  </a>
                  <sc-placeholder
                    (loaded)="triggerLoaded($event)"
                    name="header-items"
                    [rendering]="rendering$ | async"
                  ></sc-placeholder>
                </li>
                <li
                  *ngIf="!vm.minimalNavigation"
                  class="nav-item d-none d-sm-block ms-2"
                  wlAutomationId="header-request-consult-button"
                >
                  <a
                    [routerLink]="['requestConsultLink' | translate]"
                    class="btn btn-primary"
                    wlTrackLink
                    [trackLinkInfo]="{
                      rendering: { componentName: { value: 'header' } },
                      field: 'requestConsult',
                      eventType: 'navigation-click'
                    }"
                  >
                    {{ 'requestConsult' | translate }}
                    <fa-icon
                      size="lg"
                      class="cta-arrow ms-1"
                      icon="arrow-right-long"
                    ></fa-icon>
                  </a>
                </li>
                <li
                  *ngIf="vm.minimalNavigation"
                  class="nav-item d-none d-sm-block"
                  wlAutomationId="header-back-button"
                >
                  <a
                    [routerLink]="['backLink' | translate]"
                    role="menuitem"
                    class="nav-link text-dark pe-0"
                    wlTrackLink
                    [trackLinkInfo]="{
                      rendering: { componentName: { value: 'header' } },
                      field: 'backToMain',
                      eventType: 'navigation-click'
                    }"
                    >{{ 'backToMain' | translate }}</a
                  >
                </li>
              </ul>
              <ep-offcanvas
                [openOffCanvas]="isCanvasMenuOpen"
                wlAutomationId="header-offcanvas-menu"
                (closeOffCanvas)="closeCanvasMenu()"
                wlTrackLink
                [trackLinkInfo]="{
                  rendering: { componentName: { value: 'header' } },
                  field: 'Sluit canvas menu',
                  eventType: 'button-click'
                }"
              >
                <div class="off-canvas__container">
                  <img
                    [src]="'assets/logo.svg?v=2' | deployUrl"
                    alt="logo"
                    class="brand-logo d-md-none"
                  />
                  <ep-navigation
                    *ngIf="!vm.minimalNavigation"
                    [navigationItems]="vm.mainNavigationItems"
                    [language]="vm.language"
                    isVertical="true"
                    (navItemClicked)="closeCanvasMenu()"
                    wlAutomationId="header-offcanvas-main-navigation"
                    wlTrackLink
                    [trackLinkInfo]="{
                      rendering: { componentName: { value: 'header' } },
                      field: 'Sluit canvas menu',
                      eventType: 'button-click'
                    }"
                  >
                  </ep-navigation>
                  <div class="mt-5 ms-2 d-flex flex-column align-items-start">
                    <div>
                      <a
                        href="tel:{{ 'fullPhoneNumber' | translate }}"
                        class="navbar__telephone h3 p-0 me-2 d-block"
                        wlTrackLink
                        [trackLinkInfo]="{
                          rendering: { componentName: { value: 'header' } },
                          field: 'userFriendlyPhoneNumber' | translate,
                          eventType: 'button-click'
                        }"
                      >
                        <fa-icon
                          size="sm"
                          [icon]="['fas', 'phone-flip']"
                        ></fa-icon>
                        {{ 'userFriendlyPhoneNumber' | translate }}
                      </a>
                      <sc-placeholder
                        (loaded)="triggerLoaded($event)"
                        name="header-items"
                        [rendering]="rendering$ | async"
                      ></sc-placeholder>
                    </div>
                    <div
                      class="mt-3"
                      *ngIf="!vm.minimalNavigation"
                      wlAutomationId="header-offcanvas-request-consult-button"
                    >
                      <a
                        [routerLink]="['requestConsultLink' | translate]"
                        (click)="closeCanvasMenu()"
                        class="btn btn-primary"
                        wlTrackLink
                        [trackLinkInfo]="{
                          rendering: { componentName: { value: 'header' } },
                          field: 'requestConsult',
                          eventType: 'navigation-click'
                        }"
                      >
                        {{ 'requestConsult' | translate }}
                        <fa-icon
                          size="cta-arrow lg"
                          icon="arrow-right-long"
                        ></fa-icon>
                      </a>
                    </div>
                    <div
                      class="mt-3"
                      *ngIf="vm.minimalNavigation"
                      wlAutomationId="header-offcanvas-back-button"
                    >
                      <a
                        [routerLink]="['backLink' | translate]"
                        class="nav-link text-dark p-0"
                        wlTrackLink
                        [trackLinkInfo]="{
                          rendering: { componentName: { value: 'header' } },
                          field: 'backToMain',
                          eventType: 'navigation-click'
                        }"
                        >{{ 'backToMain' | translate }}</a
                      >
                    </div>
                  </div>
                </div>
              </ep-offcanvas>

              <button
                type="button"
                class="btn navbar-toggle d-xl-none p-0 ms-2"
                [ngClass]="{ 'd-sm-none': vm.minimalNavigation }"
                [attr.aria-expanded]="isCanvasMenuOpen"
                aira-label="Menu"
                (click)="openCanvasMenu()"
                wlTrackLink
                [trackLinkInfo]="{
                  rendering: { componentName: { value: 'header' } },
                  field: 'Open canvas menu',
                  eventType: 'button-click'
                }"
              >
                <fa-icon size="2x" class="ms-1" icon="bars"></fa-icon>
              </button>
            </nav>
          </header>
        </div>
      </div>
    </div>
  </div>

  <sc-placeholder
    name="header-notifications"
    [rendering]="rendering$ | async"
  ></sc-placeholder>
</ng-container>

<ul
  *ngIf="productInfo?.length"
  class="offer-info list-no-default-spacing list-unstyled"
  [class.offer-info--horizontal-margin]="horizontalMargins"
  [class.mt-1]="marginTop"
>
  <li *ngFor="let info of productInfo">
    <span>
      <fa-icon
        *ngIf="info.icon"
        [icon]="info.icon | scIcon"
        class="fa-icon"
        [fixedWidth]="true"
      ></fa-icon>
      <span *ngIf="info.key">{{ info.key }}</span>
    </span>
    <span *ngIf="info.value" class="fw-bold">{{ info.value }}</span>
  </li>
</ul>

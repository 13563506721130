import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { CustomerSupportService } from './services/customer-support.service';
import { CustomerSupportFormEffects } from './effects/customer-support-form.effects';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([CustomerSupportFormEffects]),
  ],
  providers: [CustomerSupportService],
})
export class EplusEnergiewonenCustomerSupportFormModule {}

import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./eplus-energiewonen-payment.lazy.module').then(
    (mod) => mod.EplusEnergiewonenPaymentLazyModule
  );

const eplusEnergiewonenPaymentLazyImports: ComponentNameAndModule[] = [
  {
    path: 'Payment',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'PaymentStatus',
    loadChildren: loadChildrenFunc,
  },
];
export { eplusEnergiewonenPaymentLazyImports };

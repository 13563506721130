<wl-generic-offer-container
  [products]="products"
  listName="standaloneProductOverview"
>
  <ng-template let-product>
    <div class="h-100">
      <wl-generic-offer
        [rendering]="rendering"
        [product]="product"
        (clicked)="onProductClicked(product)"
        (secondaryClicked)="onProductSecondaryClicked(product)"
        [buttonTextField]="buttonTextField(product)"
        class="d-flex h-100"
      >
        <wl-generic-price-info
          bottom
          *ngIf="product.price"
          [prefixLabel]="{ value: product.content.productPricePrefixLabel }"
          [suffixLabel]="{ value: product.content.productPriceSuffixLabel }"
          [price]="product.price"
        ></wl-generic-price-info
      ></wl-generic-offer>
    </div>
  </ng-template>
</wl-generic-offer-container>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PREFIX } from '@innogy/config';
import { map } from 'rxjs/operators';

import type {
  GetReviewsPayload,
  GetReviewsResponse,
  PaginatedReviews,
} from '../../interfaces';

@Injectable()
export class ReviewsService
  implements BaseService<GetReviewsPayload, PaginatedReviews>
{
  readonly apiVersion = '';
  readonly endpoint = `${this.apiPrefix}/energiewonen/reviews/`;

  constructor(
    private readonly http: HttpClient,
    @Inject(API_PREFIX) private readonly apiPrefix: string
  ) {}

  public call$(params: GetReviewsPayload) {
    let httpParams = new HttpParams()
      .set('page', params.page.toString())
      .set('limit', params.limit.toString());

    if (params.postalCode) {
      httpParams = httpParams.append('postal_code', params.postalCode);
    }

    if (params.showOnlyWithReviews) {
      httpParams = httpParams.append('review', 'true');
    }

    return this.http
      .get<GetReviewsResponse>(this.endpoint, { params: httpParams })
      .pipe(map((response) => this.mapResponse(response)));
  }

  private mapResponse(response: GetReviewsResponse): PaginatedReviews {
    return {
      results: response.results.map((reviewResponse) => ({
        id: reviewResponse.id,
        city: reviewResponse.city,
        zipCode: reviewResponse.zipCode,
        date: new Date(reviewResponse.date),
        numPanels: Number(reviewResponse.numPanels),
        peakPower: Number(reviewResponse.peakPower),
        image: reviewResponse.image,
        review: !reviewResponse.review
          ? undefined
          : {
              ...reviewResponse.review,
              date: new Date(reviewResponse.review.date),
              score: Number.parseFloat(reviewResponse.review.score),
            },
      })),
      pagination: response.pagination,
    };
  }
}

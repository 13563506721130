import { pipe } from 'rxjs';
import type { FormGroupState, KeyValue } from 'ngrx-forms';
import { UnfocusAction } from 'ngrx-forms';
import { ofType } from '@ngrx/effects';
import { filter, withLatestFrom } from 'rxjs/operators';
import type { MemoizedSelector, Store } from '@ngrx/store';
import { select } from '@ngrx/store';

export const ofFormControlUnfocus = <T extends KeyValue, S>(
  controls: string[],
  store$: Store<S>,
  stateSelector: MemoizedSelector<S, FormGroupState<T>>
) =>
  pipe(
    ofType<UnfocusAction>(UnfocusAction.TYPE),
    filter((action) => controls.includes(action.controlId)),
    withLatestFrom(store$.pipe(select(stateSelector)), (_, form) => form)
  );

export const eplusEnergiewonenProjectOverviewLazyImports = [
  {
    path: 'ProjectOverview',
    loadChildren: () =>
      import('./project-overview.lazy.module').then(
        (mod) => mod.ProjectOverviewLazyModule
      ),
  },
  {
    path: 'ProjectPostalCodeFilterForm',
    loadChildren: () =>
      import('./project-overview.lazy.module').then(
        (mod) => mod.ProjectOverviewLazyModule
      ),
  },
];

import { createNamedApi } from '@essent/common';
import { createAction } from '@ngrx/store';

import { UpdateStatusService } from './update-status.service';

export const updateStatusApi = createNamedApi(
  '[Payment] Update Status',
  UpdateStatusService
);

export const updatePaymentStatusFromUrlAction = createAction(
  '[Payment Update Status] Update status from url'
);

export const {
  updateStatus: UpdatePaymentStatusAction,
  updateStatusSuccess: UpdatePaymentStatusSuccessAction,
  updateStatusError: UpdatePaymentStatusErrorAction,
  updateStatusClear: UpdatePaymentStatusClearAction,
} = updateStatusApi;

import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./knowledge-base-eplus.lazy.module').then(
    (mod) => mod.EplusKnowledgeBaseLazyModule
  );

const eplusKnowledgeBaseLazyImports: ComponentNameAndModule[] = [
  {
    path: 'KnowledgeBaseOverview',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'KnowledgeBaseCategoryFilter',
    loadChildren: loadChildrenFunc,
  },
];
export { eplusKnowledgeBaseLazyImports };

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ep-offcanvas',
  templateUrl: './offcanvas.component.html',
  styleUrls: ['./offcanvas.component.ew.scss'],
})
export class OffcanvasComponent {
  @Input()
  openOffCanvas?: boolean;

  @Output()
  closeOffCanvas = new EventEmitter<void>();

  closeCanvas() {
    this.closeOffCanvas.emit();
  }
}

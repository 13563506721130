<div class="cta__background">
  <section
    class="cta position-relative"
    wlTrackImpression="contentblock-impression"
    wlAutomationId="main-content"
    [trackImpressionInfo]="rendering"
  >
    <div
      class="container-row-image d-sm-block"
      [ngStyle]="{
        'background-image':
          'url(' + rendering?.fields?.backgroundImage?.value?.src + ')'
      }"
      wlAutomationId="cta-background"
    >
      <div class="container d-none d-md-block">
        <div class="row py-4">
          <div class="col col-md-8 col-lg-6 offset-sm-4 offset-lg-6">
            <ng-container *ngTemplateOutlet="card"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="d-block d-md-none">
      <div class="container mb-2">
        <div class="row">
          <div class="col">
            <ng-container *ngTemplateOutlet="card"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #card>
  <div class="cta__body col p-4">
    <wl-rich-text-wrapper
      [rendering]="rendering"
      [field]="rendering?.fields?.text"
    >
      <div></div>
    </wl-rich-text-wrapper>
    <a
      wlAutomationId="cta-button"
      class="btn btn-primary"
      wlTrackImpression="button-impression"
      trackImpressionMode="button"
      [trackImpressionName]="rendering?.fields?.ctaButtonText?.value"
      [trackImpressionLinkurl]="rendering?.fields?.ctaButtonLink?.value.href"
      [trackImpressionInfo]="rendering"
      wlTrackLink
      [trackLinkInfo]="{
        rendering: rendering,
        field: rendering?.fields?.ctaButtonText?.value,
        eventType: 'button-click'
      }"
      *wlGenericLink="rendering?.fields?.ctaButtonLink"
    >
      {{ rendering?.fields?.ctaButtonText?.value }}
      <fa-icon
        size="lg"
        class="cta-arrow ms-1"
        icon="arrow-right-long"
      ></fa-icon>
    </a>
    <div
      *ngIf="rendering?.fields?.showCallButton?.value"
      class="pt-2"
      wlAutomationId="call-us-button"
    >
      <a
        href="tel:{{ 'fullPhoneNumber' | translate }}"
        class="btn btn-call-us call-us__button"
        wlTrackImpression="button-impression"
        trackImpressionMode="button"
        [trackImpressionName]="'userFriendlyPhoneNumber' | translate"
        [trackImpressionInfo]="rendering"
        wlTrackLink
        [trackLinkInfo]="{
          rendering: rendering,
          field: 'userFriendlyPhoneNumber' | translate,
          eventType: 'button-click'
        }"
      >
        <fa-icon
          size="lg"
          class="me-1 call-us__icon"
          [icon]="['fas', 'phone-flip']"
        ></fa-icon>
        <span [translate]="'callUs'"></span>
        {{ 'userFriendlyPhoneNumber' | translate }}
      </a>
    </div>
    <div
      *ngIf="rendering?.fields?.showOpeningHours?.value"
      class="pt-2"
      wlAutomationId="opening-hours"
    >
      <span>
        <fa-icon
          size="lg"
          class="me-1 opening-hours__icon"
          icon="clock"
        ></fa-icon>
        <span [translate]="'openingHours'"></span>
      </span>
    </div>
  </div>
</ng-template>

import { createBaseAction } from '@essent/common';

export const submitIntakeForm = createBaseAction(
  '[Intake Component] Create intake lead'
);

export const resetIntakeForm = createBaseAction(
  '[Intake Component] Reset intake form state'
);

export const successfullySubmittedIntakeForm = createBaseAction(
  '[Intake Component] Success intake form'
);

export const nextIntakeStep = createBaseAction(
  '[Intake Component] Next Intake Step'
);

export const setIntakeStep = createBaseAction<number>(
  '[Intake Component] Set Intake Step'
);

export const previousIntakeStep = createBaseAction(
  '[Intake Component] Previous Intake Step'
);

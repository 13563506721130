import type { StoreProductData } from '@innogy/eplus/temporary-core-modules';

import type { ScStoreProductData } from '../models/product.model';

export const mapScStoreProduct = (
  product: ScStoreProductData
): StoreProductData => {
  const { SuiteCrmId, Price } = product.fields;
  const {
    Title,
    SubTitle,
    Image,
    Text,
    Highlight,
    Scores = [],
    Usps = [],
    ProductInfo = [],
    SubmitButtonText,
    SubmitButtonAction,
    SubmitButtonLink,
    SecondaryButtonText,
    SecondaryButtonStyle,
    SecondaryButtonAction,
    SecondaryButtonLink,
    ProductPricePrefixLabel,
    ProductPriceSuffixLabel,
  } = product.fields.Content.fields;

  return {
    suiteCrmId: SuiteCrmId?.value ?? '',
    price: Price?.value,
    content: {
      title: Title?.value ?? '',
      subTitle: SubTitle?.value ?? '',
      image: Image?.value ?? {},
      text: Text?.value ?? '',
      highlight: Highlight?.value ?? '',
      scores: Scores.map(({ fields: { Text, OutOf5 } }) => ({
        text: Text?.value ?? '',
        outOf5: parseInt(OutOf5?.value ?? '0'),
      })),
      usps: Usps.map(({ fields: { Text, Inactive, Tooltip } }) => ({
        text: Text?.value ?? '',
        inactive: Inactive?.value ?? false,
        tooltip: Tooltip?.value ?? '',
      })),
      submitButtonText: SubmitButtonText?.value ?? '',
      productInfo: ProductInfo.map(({ fields: { Icon, Key, Value } }) => ({
        icon: Icon,
        key: Key?.value,
        value: Value?.value,
      })),
      submitButtonAction: SubmitButtonAction?.fields.value?.value,
      submitButtonLink: SubmitButtonLink?.value,
      secondaryButtonText: SecondaryButtonText?.value ?? '',
      secondaryButtonStyle: SecondaryButtonStyle?.fields.value?.value,
      secondaryButtonAction: SecondaryButtonAction?.fields.value?.value,
      secondaryButtonLink: SecondaryButtonLink?.value,
      productPricePrefixLabel: ProductPricePrefixLabel?.value,
      productPriceSuffixLabel: ProductPriceSuffixLabel?.value,
    },
  };
};

import type { AddressCheckResult } from '@essent/address';
import {
  getAddressCheck,
  getAddressCheckClear,
  getAddressCheckError,
  getAddressCheckSuccess,
} from '@essent/address';
import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';

export const getAddressCheckSelectorKey = 'getAddressCheck';
export type GetAddressCheckState = AsyncState<AddressCheckResult>;

export const getAddressCheckInitialState: GetAddressCheckState = {
  status: Status.IDLE,
};

export const getAddressCheckReducer = createAsyncStateReducer(
  getAddressCheck,
  getAddressCheckSuccess,
  getAddressCheckError,
  getAddressCheckClear
);

import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';
import type { Action } from '@ngrx/store';

import * as actions from './create-customer-support-request.actions';

export type CreateCustomerSupportRequestState = AsyncState<void>;

const _createCustomerSupportRequest = createAsyncStateReducer(
  actions.createCustomerSupportRequest,
  actions.createCustomerSupportRequestSuccess,
  actions.createCustomerSupportRequestError,
  actions.createCustomerSupportRequestClear
);

export function createCustomerSupportRequestReducer(
  state: CreateCustomerSupportRequestState = { status: Status.IDLE },
  action: Action
): CreateCustomerSupportRequestState {
  return _createCustomerSupportRequest(state, action);
}

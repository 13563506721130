<div
  *ngIf="products.length > 1"
  wlAutomationId="genericOfferCarousel.navigation"
  class="arrows"
>
  <button
    (click)="carousel?.prev()"
    [class.disabled]="isFirstProduct"
    class="btn btn-primary prev-arrow"
    wlAutomationId="genericOfferCarousel.prev"
  >
    <fa-icon icon="chevron-left"></fa-icon>
  </button>

  <wl-generic-offer-carousel-indicator
    [itemCount]="products.length"
    [currentItemIndex]="currentSlide"
  />

  <button
    (click)="carousel?.next()"
    [class.disabled]="isLastProduct"
    class="btn btn-primary next-arrow"
    wlAutomationId="genericOfferCarousel.next"
  >
    <fa-icon icon="chevron-right"></fa-icon>
  </button>
</div>

<ngb-carousel
  #carousel
  [interval]="0"
  [wrap]="false"
  [activeId]="currentSlide | toString"
  [showNavigationArrows]="false"
  [showNavigationIndicators]="false"
  (slide)="setCurrentSlide($event)"
  (swipeleft)="carousel?.next()"
  (swiperight)="carousel?.prev()"
  class="carousel"
>
  <ng-template
    ngbSlide
    *ngFor="let product of products; index as i"
    id="{{ i }}"
  >
    <div
      class="carousel-item-container d-block"
      [class.carousel-item__blank-highlight-space]="
        hasReserveHighlightSpace() && !product.content.highlight
      "
    >
      <ng-template
        [ngTemplateOutlet]="productTemplate"
        [ngTemplateOutletContext]="{
          $implicit: product,
          context: {
            index: i,
            numberOfProducts: products.length,
            listName: listName
          }
        }"
      ></ng-template>
    </div>
  </ng-template>
</ngb-carousel>

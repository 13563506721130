import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PREFIX } from '@innogy/config';

import type {
  GetIssuersPayload,
  GetIssuersResponse,
} from './get-issuers.interface';

@Injectable()
export class GetIssuersService
  implements BaseService<GetIssuersPayload, GetIssuersResponse>
{
  apiVersion = '';
  endpoint = `${this.apiPrefix}/energiewonen/ideal/issuers/`;

  constructor(
    private readonly http: HttpClient,
    @Inject(API_PREFIX) private readonly apiPrefix: string
  ) {}
  call$(body: GetIssuersPayload) {
    return this.http.post<GetIssuersResponse>(this.endpoint, body);
  }
}

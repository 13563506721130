import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { FlattenedCustomGraphQL } from '@innogy/jss';
import { getFieldsFromCustomGraphQL } from '@innogy/jss';
import { InnogyComponentRendering } from '@innogy/jss/models';

@Component({
  selector: 'ep-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.ew.scss'],
})
export class ArticleComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;

  data?: FlattenedCustomGraphQL;

  ngOnInit() {
    if (this.rendering) {
      this.data = getFieldsFromCustomGraphQL(this.rendering);
    }
  }
}

import { NgModule } from '@angular/core';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class UiOnlyFontAwesomeModule {
  constructor(library: FaIconLibrary) {
    createLibrary(library);
  }
}

export const createLibrary = (library: FaIconLibrary) => {
  library.addIcons(faInfoCircle);
};

import { from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_PREFIX } from '@innogy/config';
import type { CustomerSupportFormValues } from '@innogy/eplus-partners/customer-support-form';
import { map, switchMap } from 'rxjs/operators';

import type { Base64File } from '../interfaces/customer-support-form.interface';

@Injectable()
export class CustomerSupportService {
  private readonly path = `${this.apiPrefix}/energiewonen/customer-support`;

  constructor(
    private readonly http: HttpClient,
    @Inject(API_PREFIX) private readonly apiPrefix: string
  ) {}

  submit(form: CustomerSupportFormValues, images: File[]) {
    const base64Images = Promise.all(
      images.map((file) => this.parseFile(file))
    );

    return from(base64Images).pipe(
      map((files) => ({
        form,
        images: files,
      })),
      switchMap((requestBody) => this.http.post<void>(this.path, requestBody))
    );
  }

  parseFile(file: File) {
    return new Promise<Base64File>((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener('loadend', () => {
        const content = reader.result as string;

        resolve({
          type: file.type,
          name: file.name,
          content: content.split(',')[1],
        });
      });
      reader.addEventListener('error', (error) => reject(error));
      reader.readAsDataURL(file);
    });
  }
}

import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WINDOW } from '@innogy/utils/dom';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import {
  CreateTransactionAction,
  CreateTransactionSuccessAction,
} from './create-transaction/create-transaction.actions';
import {
  getIssuersAction,
  loadIssuersAction,
} from './get-issuers/get-issuers.actions';
import { submitPaymentFormAction } from './payment-form/payment-form.actions';
import { getPaymentFormState } from './payment-form/payment-form.selector';
import { getPaymentTokenFromUrl } from './payment.selector';
import {
  UpdatePaymentStatusAction,
  UpdatePaymentStatusErrorAction,
  updatePaymentStatusFromUrlAction,
} from './update-status/update-status.actions';

@Injectable()
export class PaymentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly route: ActivatedRoute,
    @Inject(WINDOW) private readonly windowRef: Window
  ) {}

  paymentFormState$ = this.store$.select(getPaymentFormState);
  paymentToken$ = this.store$.select(getPaymentTokenFromUrl);

  loadIssuers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadIssuersAction),
      concatLatestFrom(() => [this.paymentToken$]),
      map(([, paymentToken]) =>
        getIssuersAction({ payload: { payment_token: paymentToken } })
      )
    )
  );

  submitForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitPaymentFormAction),
      concatLatestFrom(() => [this.paymentFormState$, this.paymentToken$]),
      map(([, formState, paymentToken]) =>
        CreateTransactionAction({
          payload: {
            issuer_id: formState.value.paymentIssuer,
            payment_token: paymentToken,
          },
        })
      )
    )
  );

  createTransactionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateTransactionSuccessAction),
        tap(({ payload }) =>
          this.windowRef.location.assign(payload.redirect_url)
        )
      ),
    { dispatch: false }
  );

  updatePaymentStatusFromUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePaymentStatusFromUrlAction),
      concatLatestFrom(() => this.transactionId$),
      map(([, transaction_id]) => {
        if (!transaction_id) {
          return UpdatePaymentStatusErrorAction();
        }

        return UpdatePaymentStatusAction({
          payload: {
            transaction_id,
          },
        });
      })
    )
  );

  private get transactionId$() {
    return this.route.queryParams.pipe(map((params) => params['trxid']));
  }
}

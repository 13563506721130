import { NgModule } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBars as faBarsLight } from '@fortawesome/pro-light-svg-icons/faBars';
import { faPhoneAlt as faPhoneAltSolid } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt';
import { faLongArrowRight as faLongArrowRightRegular } from '@fortawesome/pro-regular-svg-icons/faLongArrowRight';
import { faTimes as faTimesLight } from '@fortawesome/pro-light-svg-icons/faTimes';

import { NavigationSharedModule } from './navigation.shared.module';

@NgModule({
  imports: [NavigationSharedModule],
  declarations: [],
  exports: [],
})
export class NavigationEnergiewonenModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faBarsLight,
      faPhoneAltSolid,
      faLongArrowRightRegular,
      faTimesLight
    );
  }
}

const imageMimeTypePattern = 'image/';

const checkFileType = (file: File) => {
  return String.prototype.startsWith != null
    ? file.type.startsWith(imageMimeTypePattern)
    : file.type.indexOf(imageMimeTypePattern) === 0;
};

export const checkFileTypes = (files: File[]) => {
  return files.filter(checkFileType);
};

<div class="hero">
  <div
    class="jumbotron hero__image d-flex align-items-end"
    [ngStyle]="heroImageStyling"
    wlAutomationId="article-hero"
  >
    <div class="container">
      <div class="row d-flex align-items-end">
        <div
          [class.order-12]="rendering?.fields?.textRight?.value"
          class="d-none d-md-block col-6"
        >
          <div class="hero__panel-container">
            <img
              *scImage="rendering?.fields?.panelBackgroundImage"
              class="hero__panel-background"
              alt="panel background"
            />
            <div
              class="col-12 px-3 py-6 p-lg-8 hero__body hero__body--md position-relative"
            >
              <h1 *scText="rendering?.fields?.title"></h1>
              <wl-rich-text-wrapper
                [field]="rendering?.fields?.body"
                [rendering]="rendering"
              >
                <p></p>
              </wl-rich-text-wrapper>
              <a
                *wlGenericLink="rendering?.fields?.ctaButtonLink"
                class="btn btn-primary"
                wlTrackImpression="button-impression"
                trackImpressionMode="button"
                [trackImpressionName]="rendering?.fields?.ctaButtonText?.value"
                [trackImpressionLinkurl]="
                  rendering?.fields?.ctaButtonLink?.value.href
                "
                [trackImpressionInfo]="rendering"
                wlTrackLink
                [trackLinkInfo]="{
                  rendering: rendering,
                  field: rendering?.fields?.ctaButtonText?.value,
                  eventType: 'button-click'
                }"
              >
                {{ rendering?.fields?.ctaButtonText?.value }}
              </a>
            </div>
          </div>
        </div>
        <div
          [class.order-1]="rendering?.fields?.textRight?.value"
          class="col-12 col-md-6 py-3 py-lg-5"
        >
          <sc-placeholder
            name="hero-items"
            [class.justify-content-end]="!rendering?.fields?.textRight?.value"
            class="hero__items d-flex align-items-end"
            [rendering]="rendering"
          ></sc-placeholder>
        </div>
      </div>
    </div>
  </div>
  <div class="jumbotron hero__panel-container d-block d-md-none">
    <img
      *scImage="rendering?.fields?.panelBackgroundImage"
      class="hero__panel-background"
      alt="panel background"
    />
    <div class="container py-4">
      <div class="row">
        <div class="col-12 hero__body">
          <h1 *scText="rendering?.fields?.title"></h1>
          <wl-rich-text-wrapper
            [field]="rendering?.fields?.body"
            [rendering]="rendering"
          >
            <p></p>
          </wl-rich-text-wrapper>
          <a
            *wlGenericLink="rendering?.fields?.ctaButtonLink"
            class="btn btn-primary"
            wlTrackImpression="button-impression"
            trackImpressionMode="button"
            [trackImpressionName]="rendering?.fields?.ctaButtonText?.value"
            [trackImpressionLinkurl]="
              rendering?.fields?.ctaButtonLink?.value.href
            "
            [trackImpressionInfo]="rendering"
            wlTrackLink
            [trackLinkInfo]="{
              rendering: rendering,
              field: rendering?.fields?.ctaButtonText?.value,
              eventType: 'button-click'
            }"
          >
            {{ rendering?.fields?.ctaButtonText?.value }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<span
  [ngbTooltip]="content"
  container="body"
  #tooltip="ngbTooltip"
  (click)="tooltip.open()"
  wlAutomationId="tooltip"
>
  <fa-icon icon="circle-info"></fa-icon>
</span>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

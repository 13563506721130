import { createSelector } from '@ngrx/store';
import { type CreateLeadPayload } from '@innogy/eplus-partners/energiewonen/twosolar';
import { selectUrl } from '@innogy/shared/state';
import { Gender } from '@essent/common';

import { getBasicIntakeFormState } from './basic-intake-form/basic-intake-form.selectors';

export const selectCreateLeadPayload = createSelector(
  getBasicIntakeFormState,
  selectUrl,
  (intakeForm, url): CreateLeadPayload => {
    const values = intakeForm.value;
    return {
      initials: values.initials,
      infix: values.insertion,
      last_name: values.lastName,
      gender: mapGender(values.gender),
      telephone: values.phoneNumber,
      email: values.email,
      postcode: values.postalCode,
      number: String(values.houseNumber ?? ''),
      number_ext: values.houseNumberAddition,
      city: values.residence,
      street: values.street,
      source_url: url,
      housenumberReference: values.referralHouseNumber,
      postalcodeReference: values.referralPostalCode,
    };
  }
);

function mapGender(gender: Gender) {
  switch (gender) {
    case Gender.MALE:
      return 'M';
    case Gender.FEMALE:
      return 'F';
    default:
      return 'G';
  }
}

<div class="d-flex justify-content-center">
  <span class="score-text fw-bold me-3">{{ rating.text }}</span>
  <span>
    <ng-container *ngFor="let circle of filledCircles">
      <fa-icon [icon]="['fas', 'circle']"></fa-icon>
    </ng-container>
    <ng-container *ngFor="let circle of emptyCircles">
      <fa-icon [icon]="['fal', 'circle']"></fa-icon>
    </ng-container>
  </span>
</div>

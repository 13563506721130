import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';
import { ReviewInfoGuard } from '@innogy/eplus-partners/reviews/store';

const loadChildrenFunc = () =>
  import('./eplus-components.lazy.module').then(
    (mod) => mod.EplusComponentsLazyModule
  );

const eplusComponentsLazyImports: ComponentNameAndModule[] = [
  {
    path: 'CallToAction',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'FunctionalUsp',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'KlantenVertellen',
    loadChildren: loadChildrenFunc,
    canActivate: ReviewInfoGuard,
  },
  {
    path: 'Image',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'BibliotheekArtikel',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'Hero',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'HeroTextRight',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'ProductGridItem',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'PrimitiveBlock',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'Separator',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'GridCrosslinkModule',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'ImageCard',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'BusinessHoursIndicator',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'CustomerQuoteContainer',
    loadChildren: loadChildrenFunc,
  },
];

export { eplusComponentsLazyImports };

import type { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

const PREFIX = '[Create Lead]';

export const createLead = createAction(`${PREFIX} create`);
export const createLeadSuccess = createAction(`${PREFIX} success`);
export const createLeadError = createAction(
  `${PREFIX} error`,
  props<{ error: HttpErrorResponse }>()
);
export const createLeadClear = createAction(`${PREFIX} clear`);

<div
  class="py-2 d-flex justify-content-center align-items-center flex-wrap flex-{{
    orientation
  }}"
  [class.price-info--has-content-below]="hasContentBelow"
>
  <ng-container *ngIf="prefixLabel">
    <span class="price-info--prefix-left" *scText="prefixLabel"></span>
  </ng-container>
  <span class="price-info--price mx-2 mb-0 h1">
    <ng-container
      *ngTemplateOutlet="price % 1 === 0 ? roundedPrice : decimalPrice"
    ></ng-container>
  </span>
  <ng-container *ngIf="suffixLabel">
    <span class="price-info--prefix-right" *scText="suffixLabel"></span>
  </ng-container>
</div>

<ng-template #roundedPrice>
  {{ price | currency : 'EUR' : 'symbol' : '1.0-0' }},-
</ng-template>
<ng-template #decimalPrice>
  {{ price | currency : 'EUR' : 'symbol' : '1.0-2' }}
</ng-template>

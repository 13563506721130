import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { ImageField } from '@sitecore-jss/sitecore-jss-angular';
import type { ImageFieldValue } from '@innogy/jss/models';

import { placeholderImage } from './image-placeholder';

type ImagePlaceholder = {
  src: string;
  alt: string;
};

@Pipe({
  name: 'imagePlaceholder',
})
export class ImagePlaceholderPipe implements PipeTransform {
  transform(image?: ImageField | ImageFieldValue | null): ImagePlaceholder {
    if (image) {
      if (isImageField(image)) {
        if (image.value?.src) {
          return image.value as ImagePlaceholder;
        }
      } else if (image.src) {
        return image as ImagePlaceholder;
      }
    }
    return placeholderImage as ImagePlaceholder;
  }
}

function isImageField(
  image?: ImageField | ImageFieldValue
): image is ImageField {
  return (image as ImageField).value !== undefined;
}

import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { GridItem } from '@innogy/eplus-partners/ui-only';
import { getFieldValue } from '@innogy/jss/utils';
import { InnogyComponentRendering } from '@innogy/jss/models';
import type { ImageField, LinkField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'ep-grid-crosslink-module',
  templateUrl: './grid-crosslink-module.component.html',
  styleUrls: [],
})
export class GridCrosslinkModuleComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;
  crosslinks?: GridItem[];
  hasTitle?: boolean;
  hasLink?: boolean;
  hasContent?: boolean;

  ngOnInit(): void {
    this.hasTitle = getFieldValue(this.rendering, 'title') || false;
    this.hasLink =
      getFieldValue<LinkField>(this.rendering, 'link')?.href !== '' || false;
    this.hasContent = getFieldValue(this.rendering, 'content') || false;

    if (this.rendering?.fields?.crosslinks) {
      this.crosslinks = this.crosslinksToGridItems(
        this.rendering.fields.crosslinks as any[]
      );
    }
  }

  crosslinksToGridItems = (crosslinks: any[]) => {
    return crosslinks.map((crosslink: any) => ({
      image: getFieldValue<ImageField>(crosslink.fields, 'crosslinkImage'),
      title: getFieldValue<string>(crosslink.fields, 'crosslinkTitle'),
      summary: getFieldValue<string>(crosslink.fields, 'crosslinkSummary'),
      url: getFieldValue<LinkField>(crosslink.fields, 'crosslinkSelfUrl')?.href,
    }));
  };
}

import type { EffectConfiguration } from '@essent/common';

import type { ReviewsInfo } from '../../interfaces';
import * as actions from '../actions';

export const getReviewInfoEffectsConfig: EffectConfiguration<
  void,
  ReviewsInfo
> = {
  requestAction: actions.getReviewInfo,
  successAction: actions.getReviewInfoSuccess,
  errorAction: actions.getReviewInfoError,
};

import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import { getCustomerSupportSelector } from '../reducers';

export const createCustomerSupportRequestSelectorKey =
  'createCustomerSupportRequest';
export const getCreateCustomerSupportRequestState = createSelector(
  getCustomerSupportSelector,
  (state) => state?.create
);

export const getCreateCustomerSupportRequestStatus = createSelector(
  getCreateCustomerSupportRequestState,
  (state) => state.status
);

export const isCreateCustomerSupportRequestLoading = createSelector(
  getCreateCustomerSupportRequestStatus,
  (status) => status === Status.PENDING
);
export const hasCreateCustomerSupportRequestError = createSelector(
  getCreateCustomerSupportRequestStatus,
  (status) => status === Status.ERROR
);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiProgressiveFormsModule } from '@innogy/common-ui/progressive-forms';
import {
  DefaultComponentModule,
  DefaultFormComponentModule,
} from '@innogy/core/modules/components';

import { BaseEplusWizardComponent } from './base-eplus-wizard/base-eplus-wizard.component';
import { EplusProgressiveAddressFormComponent } from './eplus-progressive-address-form/eplus-progressive-address-form.component';
import { EplusProgressiveConfirmationFormComponent } from './eplus-progressive-confirmation-form/eplus-progressive-confirmation-form.component';
import { EplusProgressivePersonalDetailsFormComponent } from './eplus-progressive-personal-details-form/eplus-progressive-personal-details-form.component';

@NgModule({
  imports: [
    CommonModule,
    CommonUiProgressiveFormsModule,
    DefaultComponentModule,
    DefaultFormComponentModule,
  ],
  declarations: [
    BaseEplusWizardComponent,
    EplusProgressiveAddressFormComponent,
    EplusProgressivePersonalDetailsFormComponent,
    EplusProgressiveConfirmationFormComponent,
  ],
  exports: [
    EplusProgressiveAddressFormComponent,
    EplusProgressivePersonalDetailsFormComponent,
    EplusProgressiveConfirmationFormComponent,
  ],
})
export class EplusSharedModule {}

import { createBaseAction } from '@essent/common';
import { createAction } from '@ngrx/store';

export const disableStreetAndResidenceAction = createAction(
  '[Basic Intake Form] Disable street and city'
);

export const resetBasicIntakeFormState = createBaseAction(
  '[Basic Intake Form] Reset'
);

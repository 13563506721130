<div class="mb-2">
  <ng-container *ngIf="item?.url; else content">
    <a [routerLink]="item?.url" class="text-decoration-none text-reset">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  </ng-container>
</div>

<ng-template #content>
  <div class="grid-item">
    <img
      loading="lazy"
      *ngIf="item?.image?.src"
      [src]="item?.image?.src"
      [alt]="item?.image?.alt"
      class="grid-item__image"
    />
    <div class="p-2">
      <h3
        *ngIf="item?.title"
        class="grid-item__title mb-1"
        [ngClass]="{ 'grid-item__title--link': item?.url }"
      >
        {{ item?.title }}
      </h3>
      <p *ngIf="item?.summary" class="grid-item__summary fw-normal">
        {{ item?.summary }}
      </p>
    </div>
  </div>
</ng-template>

import { Status } from '@essent/common';
import { getSitecoreContextWithStatus } from '@innogy/jss';
import { getSettingsObject } from '@innogy/settings';
import { createSelector } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import type { JssBlogRoot } from '../knowledge-base.model';

export const getBlogNodeNavigation = pipe(
  getSitecoreContextWithStatus,
  map(({ context, status }) => {
    const navPresent = context != null && context.blogNodeNavigation != null;

    const requestPending = status === Status.PENDING || status === Status.IDLE;
    const isNavigationAvailable =
      (requestPending || navPresent) && status !== Status.ERROR;

    let navItem: JssBlogRoot;
    if (isNavigationAvailable && context.blogNodeNavigation) {
      navItem = context.blogNodeNavigation;
    } else {
      navItem = {};
    }

    return {
      isNavigationAvailable,
      navItem,
    };
  })
);

export const getBlogNodeNavigationMain = pipe(
  getBlogNodeNavigation,
  map((info) => info.navItem)
);

export const getBlogNodeNumberOfArticles = pipe(
  getBlogNodeNavigationMain,
  map((info) => info.nrOfArticles)
);

export const getBlogNodeNumberOfPages = pipe(
  getBlogNodeNavigationMain,
  map((info) => info.nrOfPages)
);

export const getBlogNodePageSize = pipe(
  getBlogNodeNavigationMain,
  map((info) => info.pageSize)
);

export const getBlogNodeMainCategories = pipe(
  getBlogNodeNavigationMain,
  map((info) => info.mainCategories || [])
);

export const getBlogNodeSubCategories = pipe(
  getBlogNodeNavigationMain,
  map((info) => info.subCategories || [])
);

export const hasSubcategories = pipe(
  getBlogNodeSubCategories,
  map((subCategories) => subCategories != null && subCategories.length !== 0)
);

export const getBlogNodeArticles = pipe(
  getBlogNodeNavigationMain,
  map((info) => info.articles || [])
);

export const hasArticles = pipe(
  getBlogNodeArticles,
  map((articles) => articles != null && articles.length !== 0)
);

export const getActiveMainCategory = pipe(
  getBlogNodeMainCategories,
  map((categories) => {
    const activeCategory = categories.find(
      (category) => category.active || false
    );

    if (!activeCategory) {
      return null;
    }

    return activeCategory;
  })
);

export const getActiveSubCategory = pipe(
  getBlogNodeSubCategories,
  map((subCategories) => {
    const activeSubCategory = subCategories.find(
      (category) => category.active || false
    );

    if (!activeSubCategory) {
      return null;
    }

    return activeSubCategory;
  })
);

export const knowledgeBaseSettingsKey = 'knowledgeBase';
export const knowledgeBaseIdsCategoryKey = 'knowledgeBaseIds';
export const knowledgeBaseIdsKey = 'ids';
export const blogDetailPageKey = 'BlogDetailPageTemplateId';

export const getBlogDetailPageTemplateId = createSelector(
  getSettingsObject<string>(knowledgeBaseSettingsKey, blogDetailPageKey),
  (setting) => setting
);

export const getBlogTemplateIds = createSelector(
  getSettingsObject<string[]>(knowledgeBaseIdsCategoryKey, knowledgeBaseIdsKey),
  (keys) => keys || []
);

import { Component, Input } from '@angular/core';
import { TextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'wl-generic-price-info[price]',
  templateUrl: './generic-price-info.component.html',
  styleUrls: [
    './generic-price-info.component.essent.scss',
    './generic-price-info.component.ed.scss',
  ],
})
export class GenericPriceInfoComponent {
  @Input()
  prefixLabel?: TextField;
  @Input()
  suffixLabel?: TextField;
  @Input()
  price!: number;
  @Input()
  orientation: 'row' | 'column' = 'row';
  @Input()
  hasContentBelow = false;
}

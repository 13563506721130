import { NgModule } from '@angular/core';
import { CommonUiPartialsModule } from '@innogy/common-ui/partials';
import { CommonUiProductsModule } from '@innogy/common-ui/products';
import { CommonUiSalesModule } from '@innogy/common-ui/sales';
import {
  DefaultComponentModule,
  DefaultFormComponentModule,
} from '@innogy/core/modules/components';
import { EplusSharedModule } from '@innogy/eplus/shared';
import { JssUtilsModule } from '@innogy/jss/utils';
import { SharedProgressiveFormSitecoreModule } from '@innogy/shared/progressive-form/sitecore';
import { SitecoreFormsModule } from '@innogy/sitecore-forms';
import {
  NgbCollapseModule,
  NgbModalModule,
  NgbProgressbarModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgProgressModule } from 'ngx-progressbar';

import { SolarPanelsLoaderComponent } from './solar-panels-loader/solar-panels-loader.component';
import { SolarPanelsOrderFormContainerComponent } from './solar-panels-order-form-container/solar-panels-order-form-container.component';
import { SolarPanelsOrderFormComponent } from './solar-panels-order-form/solar-panels-order-form.component';
import { SolarPanelsProductInfoComponent } from './solar-panels-product-info/solar-panels-product-info.component';
import { SolarPanelsProductOverviewContainerComponent } from './solar-panels-product-overview-container/solar-panels-product-overview-container.component';
import { SolarPanelsReceiptContainerComponent } from './solar-panels-receipt-container/solar-panels-receipt-container.component';
import { SolarPanelsResultOverviewContainerComponent } from './solar-panels-result-overview-container/solar-panels-result-overview-container.component';
import { SolarPanelsResultOverviewMapComponent } from './solar-panels-result-overview-map/solar-panels-result-overview-map.component';
import { SolarPanelsResultOverviewSummaryContainerComponent } from './solar-panels-result-overview-summary-container/solar-panels-result-overview-summary-container.component';
import { SolarPanelsSuitabilityCheckContainerComponent } from './solar-panels-suitability-check-container/solar-panels-suitability-check-container.component';
import { SolarPanelsSuitabilityCheckComponent } from './solar-panels-suitability-check/solar-panels-suitability-check.component';
import { SolarPanelsWizardContainerComponent } from './solar-panels-wizard-container/solar-panels-wizard-container.component';

@NgModule({
  imports: [
    DefaultComponentModule,
    DefaultFormComponentModule,
    CommonUiProductsModule,
    CommonUiPartialsModule,
    SitecoreFormsModule,
    NgbProgressbarModule,
    NgProgressModule,
    NgbCollapseModule,
    SharedProgressiveFormSitecoreModule,
    EplusSharedModule,
    NgbCollapseModule,
    TranslateModule.forRoot(),
    CommonUiSalesModule,
    NgbModalModule,
    JssUtilsModule,
  ],
  declarations: [
    SolarPanelsOrderFormContainerComponent,
    SolarPanelsOrderFormComponent,
    SolarPanelsProductOverviewContainerComponent,
    SolarPanelsProductInfoComponent,
    SolarPanelsReceiptContainerComponent,
    SolarPanelsSuitabilityCheckContainerComponent,
    SolarPanelsWizardContainerComponent,
    SolarPanelsSuitabilityCheckComponent,
    SolarPanelsResultOverviewContainerComponent,
    SolarPanelsResultOverviewMapComponent,
    SolarPanelsResultOverviewSummaryContainerComponent,
    SolarPanelsLoaderComponent,
  ],
  exports: [SolarPanelsSuitabilityCheckComponent],
})
export class EplusSolarPanelsModule {}

import { Component, Input, TemplateRef, type OnInit } from '@angular/core';
import type { GenericProductData } from '@innogy/common-ui/models';
import type { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wl-generic-offer-carousel',
  templateUrl: './generic-offer-carousel.component.html',
  styleUrls: [
    './generic-offer-carousel.component.ed.scss',
    './generic-offer-carousel.component.essent.scss',
  ],
})
export class GenericOfferCarouselComponent implements OnInit {
  @Input({ required: true }) products!: GenericProductData[];
  @Input({ required: true }) productTemplate!: TemplateRef<any>;
  @Input({ required: true }) listName!: string;

  currentSlide = 0;

  ngOnInit(): void {
    if (this.products.length > 2) {
      // get center slide and set as current
      this.currentSlide = Math.round((this.products.length - 1) / 2);
    }
  }

  get isFirstProduct() {
    return this.currentSlide === 0;
  }

  get isLastProduct() {
    return this.currentSlide === this.products.length - 1;
  }

  setCurrentSlide(event: NgbSlideEvent) {
    this.currentSlide = Number(event.current);
  }

  hasReserveHighlightSpace() {
    return this.products.some((e) => e.content.highlight);
  }
}

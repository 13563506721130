import { NgModule } from '@angular/core';
import {
  DefaultComponentModule,
  DefaultFormComponentModule,
} from '@innogy/core/modules/components';
import { EplusIconModule } from '@innogy/icons';
import { UtilsEnvironmentModule } from '@innogy/utils/environment';

import { DisableDocumentScrollingDirective } from './disable-document-scrolling/disable-document-scrolling.directive';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { OffcanvasComponent } from './offcanvas/offcanvas.component';

@NgModule({
  imports: [
    DefaultComponentModule,
    DefaultFormComponentModule,
    EplusIconModule,
    UtilsEnvironmentModule,
  ],
  declarations: [
    HeaderComponent,
    NavigationComponent,
    DisableDocumentScrollingDirective,
    OffcanvasComponent,
  ],
  exports: [HeaderComponent],
})
export class EplusHeaderModule {}

<ng-progress *wlClientOnly aria-label="Aan het laden..."></ng-progress>
<wl-environment-classes></wl-environment-classes>
<wl-visitor-identification></wl-visitor-identification>
<wl-setup-anchor-scrolling></wl-setup-anchor-scrolling>
<wl-load-fonts font="WorkSans"></wl-load-fonts>
<ep-header
  wlTrackImpression="navigation-impression"
  trackImpressionMode="any"
  [trackImpressionInfo]="{
    name: 'headernavigation'
  }"
></ep-header>
<router-outlet></router-outlet>
<wl-analytics-setup></wl-analytics-setup>
<wl-cookiewall-container></wl-cookiewall-container>

<div
  *ngIf="hasTitle || hasLink"
  class="d-flex justify-content-between align-items-baseline"
>
  <h2 *scText="rendering?.fields?.title" class="mb-4"></h2>
  <a *wlGenericLink="rendering?.fields?.link" class="d-none d-sm-block"></a>
</div>
<wl-rich-text-wrapper
  *ngIf="rendering?.fields?.content?.value"
  class="mb-4 mt-n2"
  [field]="rendering?.fields?.content"
  [rendering]="rendering"
>
  <div></div>
</wl-rich-text-wrapper>
<ep-grid-overview [items]="crosslinks"></ep-grid-overview>
<div *ngIf="hasLink" class="d-flex justify-content-end align-items-baseline">
  <a *wlGenericLink="rendering?.fields?.link" class="d-block d-sm-none"></a>
</div>

import { selectCurrentRoute } from '@innogy/shared/state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { PaymentState } from './payment.reducer';
import { paymentReducerSelectorKey } from './payment.reducer';

export const getPaymentState = createFeatureSelector<PaymentState>(
  paymentReducerSelectorKey
);

export const getPaymentTokenFromUrl = createSelector(
  selectCurrentRoute,
  (route) => {
    const url = route?.url ?? [];

    // Do not use pop() here since this will modify the actual array in the state
    return url[url.length - 1]?.path;
  }
);

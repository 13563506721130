<ng-container *ngIf="deviceInfo$ | async as deviceInfo">
  <ng-container *ngIf="deviceInfo.isMobile; then carousel; else cards">
  </ng-container>
</ng-container>

<ng-template #carousel>
  <wl-generic-offer-carousel
    [products]="products"
    [listName]="listName"
    [productTemplate]="productTemplate"
  ></wl-generic-offer-carousel>
</ng-template>

<ng-template #cards>
  <div class="row justify-content-center">
    <div
      *ngFor="let product of products; index as index"
      class="col-6 col-lg-4"
      [class.product__blank-highlight-space]="
        hasReserveHighlightSpace && !product.content.highlight
      "
    >
      <ng-template
        [ngTemplateOutlet]="productTemplate"
        [ngTemplateOutletContext]="{
          $implicit: product,
          context: {
            index: index,
            numberOfProducts: products.length,
            listName: listName
          }
        }"
      ></ng-template>
    </div>
  </div>
</ng-template>

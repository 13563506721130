<div
  wlTrackImpression="contentblock-impression"
  [trackImpressionInfo]="rendering"
  [class.text-center]="center"
>
  <fa-icon
    *ngIf="icon"
    [icon]="icon | scIcon"
    class="functional-usp__icon"
    [ngClass]="iconColor"
    wlAutomationId="functional-usp-icon"
  ></fa-icon>
  <ng-container *ngIf="fields?.topTitle">
    <p
      *scText="fields?.topTitle"
      class="intro mb-0"
      wlAutomationId="functional-usp-toptitle"
    ></p>
  </ng-container>
  <p
    *scText="fields?.title"
    class="h3 mt-1 mb-1"
    wlAutomationId="functional-usp-title"
  ></p>
  <p
    *scText="fields?.text"
    class="mb-0"
    wlAutomationId="functional-usp-text"
  ></p>
</div>

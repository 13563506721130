<div class="image-card">
  <div class="image-card__image-container">
    <img
      [src]="image?.src"
      [alt]="image?.alt"
      class="image-card__image"
      loading="lazy"
    />
  </div>
  <div class="image-card__content p-2">
    <ng-content></ng-content>
  </div>
</div>

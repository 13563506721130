<div
  class="off-canvas"
  [class.off-canvas--show]="openOffCanvas"
  [epDisableDocumentScrolling]="openOffCanvas"
>
  <div
    class="off-canvas__header justify-content-end align-items-center me-3 mt-2"
  >
    <button
      type="button"
      class="btn-close float-end"
      aria-label="Sluiten"
      (click)="closeCanvas()"
      wlTrackLink
      [trackLinkInfo]="{
        rendering: { componentName: { value: 'canvas menu' } },
        field: 'Sluit canvas menu',
        eventType: 'button-click'
      }"
    ></button>
  </div>
  <div class="off-canvas__content d-flex justify-content-start flex-column">
    <ng-content></ng-content>
  </div>
</div>

<div
  class="backdrop"
  [class.backdrop--show]="openOffCanvas"
  (click)="closeCanvas()"
></div>

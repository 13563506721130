import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { GridItem } from '@innogy/eplus-partners/ui-only';
import type { State } from '@innogy/jss';
import { getLanguage, JssRouteBuilderService } from '@innogy/jss';
import type { JssRoute } from '@innogy/jss/models';
import type {
  JssBlogArticle,
  JssBlogBase,
} from '@innogy/knowledge-base/shared';
import {
  getActiveMainCategory,
  getBlogNodeArticles,
  getBlogNodeMainCategories,
  getBlogNodeNavigationMain,
  getBlogNodeNumberOfArticles,
  getBlogNodeNumberOfPages,
} from '@innogy/knowledge-base/shared';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KnowledgeBaseService {
  language$ = this.store$.pipe(select(getLanguage));
  main$ = this.store$.pipe(getBlogNodeNavigationMain);
  navItems$ = this.store$.pipe(getBlogNodeMainCategories);
  blogArticles$ = this.store$.pipe(
    getBlogNodeArticles,
    map((navItems) =>
      navItems.map(
        (item: JssBlogArticle): GridItem => ({
          image: { src: item.imageUrl, alt: 'Item Photo' },
          url: item.url,
          title: item.title,
          summary: item.summary,
        })
      )
    )
  );
  numberOfPages$ = this.store$.pipe(getBlogNodeNumberOfPages);
  numberOfArticles$ = this.store$.pipe(getBlogNodeNumberOfArticles);
  selected$ = combineLatest([
    this.store$.pipe(getActiveMainCategory),
    this.store$.pipe(getBlogNodeNavigationMain),
  ]).pipe(
    map(([mainCategory, main]) => {
      return mainCategory == null ? main : mainCategory;
    })
  );

  getRouteUrl(navItem: JssBlogBase, language: string) {
    const jssRoute: JssRoute = {
      language,
      serverRoute: navItem.url,
    };

    return this.urlBuilder.buildRouteUrl(jssRoute);
  }

  onChange(navItem: JssBlogBase, language: string) {
    this.router.navigateByUrl(this.getRouteUrl(navItem, language));
  }

  constructor(
    private readonly store$: Store<State>,
    private readonly urlBuilder: JssRouteBuilderService,
    private readonly router: Router
  ) {}
}

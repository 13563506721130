import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  DefaultComponentModule,
  DefaultFormComponentModule,
} from '@innogy/core/modules/components';

import { eplusReducer } from './+state/reducers';
import { UiOnlyFontAwesomeModule } from './fa.module';
import { FormPageComponent } from './form-page/form-page.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ColumnCardComponent } from './column-card/column-card.component';
import { GridOverviewComponent } from './grid-overview/grid-overview.component';
import { GridItemComponent } from './grid-item/grid-item.component';
import { ImageCardComponent } from './image-card/image-card.component';
import { SendMoreInformationEffects } from './+state/send-more-information/send-more-information.effects';
import { SendMoreInformationComponent } from './send-more-information/send-more-information.component';
import { InfoBlockComponent } from './info-block/info-block.component';
import { CustomerQuoteCardComponent } from './customer-quote-card/customer-quote-card.component';

const exportComponents = [
  TooltipComponent,
  GridOverviewComponent,
  GridItemComponent,
  FormPageComponent,
  ColumnCardComponent,
  ImageCardComponent,
  SendMoreInformationComponent,
  InfoBlockComponent,
  CustomerQuoteCardComponent,
];
const components = [...exportComponents];
@NgModule({
  imports: [
    DefaultComponentModule,
    DefaultFormComponentModule,
    NgbTooltipModule,
    UiOnlyFontAwesomeModule,
    StoreModule.forFeature('eplus', eplusReducer),
    EffectsModule.forFeature([SendMoreInformationEffects]),
  ],
  declarations: [components],
  exports: [exportComponents],
})
export class EplusUiOnlyModule {}

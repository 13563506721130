import { Component, Input } from '@angular/core';
import { RichTextField } from '@sitecore-jss/sitecore-jss-angular';

@Component({
  selector: 'ep-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.ew.scss'],
})
export class InfoBlockComponent {
  @Input() text?: RichTextField;
}

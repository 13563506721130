import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from '@innogy/common-ui/utility-components';
import { EplusUiOnlyModule } from '@innogy/eplus-partners/ui-only';
import { DefaultComponentModule } from '@innogy/core/modules/components';

import { KnowledgeBaseCategoryFilterComponent } from './category-filter/knowledge-base-category-filter.component';
import { KnowledgeBaseService } from './knowledge-base.service';
import { KnowledgeBaseOverviewComponent } from './overview/knowledge-base-overview.component';

@NgModule({
  imports: [
    DefaultComponentModule,
    EplusUiOnlyModule,
    PaginationModule,
    FormsModule,
  ],
  declarations: [
    KnowledgeBaseOverviewComponent,
    KnowledgeBaseCategoryFilterComponent,
  ],
  providers: [KnowledgeBaseService],
  exports: [
    KnowledgeBaseOverviewComponent,
    KnowledgeBaseCategoryFilterComponent,
  ],
})
export class EplusKnowledgeBaseModule {}

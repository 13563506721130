import { createBaseAction } from '@essent/common';

export const filesDropped = createBaseAction<File[]>(
  '[Image Upload] Files dropped'
);
export const filesSelected = createBaseAction<File[]>(
  '[Image Upload] Files selected'
);
export const validateFiles = createBaseAction<File[]>(
  '[Image Upload] Validate files'
);
export const addFiles = createBaseAction<File[]>('[Image Upload] Add files');
export const setFiles = createBaseAction<File[]>('[Image Upload] Set files');
export const removeFile = createBaseAction<File>('[Image Upload] Remove file');
export const resetImageUpload = createBaseAction<File>('[Image Upload] Reset');

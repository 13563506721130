import { NgModule } from '@angular/core';
import {
  FaConfig,
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';

import * as eplusIcons from './eplus-icons';
import { createLibrary } from '../utils';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class EplusIconModule {
  constructor(faLibrary: FaIconLibrary, faConfig: FaConfig) {
    faConfig.defaultPrefix = 'fal';
    faLibrary.addIcons(...createLibrary(eplusIcons));
  }
}

import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { CreateLeadState } from './create-lead/create-lead.reducer';
import { createLeadReducer } from './create-lead/create-lead.reducer';
import type { IntakeFormState } from './basic-intake-form/basic-intake-form.reducer';
import { intakeFormReducer } from './basic-intake-form/basic-intake-form.reducer';
import type { GetAddressCheckState } from './address-check/address-check.reducer';
import { getAddressCheckReducer } from './address-check/address-check.reducer';
import type { IntakeComponentState } from './intake-component/intake.reducer';
import { intakeComponentReducer } from './intake-component/intake.reducer';

export interface LeadsState {
  readonly create: CreateLeadState;
  readonly basicIntakeForm: IntakeFormState;
  readonly getAddressCheck: GetAddressCheckState;
  readonly intakeComponent: IntakeComponentState;
}

export const leadsSelectorKey = 'leads';
export const getLeadsState =
  createFeatureSelector<LeadsState>(leadsSelectorKey);
export const leadsReducer: ActionReducerMap<LeadsState, Action> = {
  create: createLeadReducer,
  basicIntakeForm: intakeFormReducer,
  getAddressCheck: getAddressCheckReducer,
  intakeComponent: intakeComponentReducer,
};

import { DOCUMENT } from '@angular/common';
import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Directive, Inject, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[epDisableDocumentScrolling]',
})
export class DisableDocumentScrollingDirective
  implements OnInit, OnDestroy, OnChanges
{
  @Input('epDisableDocumentScrolling')
  active?: boolean;

  constructor(
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.active) {
      return;
    }

    if (changes.active.currentValue) {
      this.disableScrolling();
    } else {
      this.enableScrolling();
    }
  }

  ngOnInit(): void {
    if (!this.active) {
      return;
    }

    this.disableScrolling();
  }

  ngOnDestroy(): void {
    this.enableScrolling();
  }

  private disableScrolling() {
    this.renderer.addClass(this.document.body, 'disable-scrolling');
  }

  private enableScrolling() {
    this.renderer.removeClass(this.document.body, 'disable-scrolling');
  }
}

import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { SendMoreInformationFormState } from './send-more-information/send-more-information.reducer';
import { sendMoreInformationReducer } from './send-more-information/send-more-information.reducer';

export interface EplusState {
  readonly sendMoreInformation: SendMoreInformationFormState;
}

export const eplusSelectorKey = 'eplus';
export const getEplusState =
  createFeatureSelector<EplusState>(eplusSelectorKey);
export const eplusReducer: ActionReducerMap<EplusState, Action> = {
  sendMoreInformation: sendMoreInformationReducer,
};

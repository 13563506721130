import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';
import type { Action } from '@ngrx/store';

import type { ReviewsInfo } from '../../interfaces';
import * as actions from '../actions/get-review-info.actions';

export type ReviewInfoState = AsyncState<ReviewsInfo>;

const initalState: ReviewInfoState = { status: Status.IDLE };
const _getReviewInfo = createAsyncStateReducer(
  actions.getReviewInfo,
  actions.getReviewInfoSuccess,
  actions.getReviewInfoError,
  actions.getReviewInfoClear
);

export function ReviewInfoReducer(
  state: ReviewInfoState = initalState,
  action: Action
): ReviewInfoState {
  return _getReviewInfo(state, action);
}

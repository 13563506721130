import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import { getReviewsState } from './feature.selectors';

const getReviewInfo = createSelector(
  getReviewsState,
  (feature) => feature?.info
);

const faqHasError = createSelector(
  getReviewInfo,
  (state) => state?.status === Status.ERROR
);

export const getReviewInfoData = createSelector(
  getReviewInfo,
  (state) => state?.data
);

const getReviewInfoStatus = createSelector(
  getReviewInfo,
  (state) => state?.status
);

export const getReviewInfoIsLoading = createSelector(
  getReviewInfoStatus,
  faqHasError,
  (status, hasError) =>
    (status === Status.PENDING || status === Status.IDLE) && !hasError
);
export const getReviewInfoHasError = createSelector(
  getReviewInfoStatus,
  (status) => status === Status.ERROR
);

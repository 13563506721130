import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  addFiles,
  removeFile,
  resetImageUpload,
  setFiles,
} from './image-upload.actions';

export interface ImageUploadState {
  files: File[];
}

export const initialState: ImageUploadState = {
  files: [],
};

const _reducer = createReducer(
  initialState,
  on(setFiles, (state, action) => ({
    ...state,
    files: action.payload,
  })),
  on(addFiles, (state, action) => ({
    ...state,
    files: [...state.files, ...action.payload],
  })),
  on(removeFile, (state, action) => ({
    ...state,
    files: state.files.filter((file) => action.payload !== file),
  })),
  on(resetImageUpload, () => initialState)
);

export function imageUploadReducer(
  state: ImageUploadState = initialState,
  action: Action
): ImageUploadState {
  return _reducer(state, action);
}

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  addFiles,
  filesDropped,
  filesSelected,
  validateFiles,
} from './image-upload.actions';
import { checkFileTypes } from './image-upload-helpers';

@Injectable()
export class ImageUploadEffects {
  validateFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateFiles),
      map(({ payload }) => checkFileTypes(payload)),
      map((validatedFiles) => addFiles({ payload: validatedFiles }))
    )
  );

  filesDropped$ = createEffect(() =>
    this.actions$.pipe(
      ofType(filesDropped),
      map(({ payload }) => validateFiles({ payload }))
    )
  );

  filesSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(filesSelected),
      map(({ payload }) => validateFiles({ payload }))
    )
  );

  constructor(private readonly actions$: Actions) {}
}

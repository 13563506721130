import { createBaseAction } from '@essent/common';
import { createAction, props } from '@ngrx/store';

export const submitCustomerSupportForm = createBaseAction(
  '[Customer Support Form Component] Create customer support form request'
);

export const resetCustomerSupportForm = createBaseAction(
  '[Customer Support Form Component] Reset customer support form state'
);

export const successfullySubmittedCustomerSupportForm = createBaseAction(
  '[Customer Support Form Component] Success customer support form'
);

export const nextCustomerSupportStep = createAction(
  '[Customer Support Form Component] Next customer support Step'
);

export const setCustomerSupportStep = createBaseAction<number>(
  '[Customer Support Form Component] Set customer support Step'
);

export const previousCustomerSupportStep = createAction(
  '[Customer Support Form Component] Previous customer support Step'
);

export const setShowNoPhotosWarning = createAction(
  '[Customer Support Form Component] Set show no photos warning',
  props<{ payload: boolean }>()
);

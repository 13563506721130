import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  nextCustomerSupportStep,
  previousCustomerSupportStep,
  resetCustomerSupportForm,
  setCustomerSupportStep,
  setShowNoPhotosWarning,
} from './customer-support.actions';

export interface CustomerSupportComponentState {
  page: number;
  showNoPhotosWarning: boolean;
}

export const initialState: CustomerSupportComponentState = {
  page: 1,
  showNoPhotosWarning: false,
};

const _reducer = createReducer(
  initialState,
  on(nextCustomerSupportStep, (state) => ({
    ...state,
    page: state.page + 1,
  })),
  on(previousCustomerSupportStep, (state) => ({
    ...state,
    page: state.page - 1,
  })),
  on(setCustomerSupportStep, (state, { payload }) => ({
    ...state,
    page: payload,
  })),
  on(setShowNoPhotosWarning, (state, { payload }) => ({
    ...state,
    showNoPhotosWarning: payload,
  })),
  on(resetCustomerSupportForm, () => initialState)
);

export function customerSupportComponentReducer(
  state: CustomerSupportComponentState = initialState,
  action: Action
): CustomerSupportComponentState {
  return _reducer(state, action);
}

import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import { getReviewsState } from './feature.selectors';

const createReviewState = createSelector(
  getReviewsState,
  (state) => state?.create
);
const createReviewStatus = createSelector(
  createReviewState,
  (state) => state.status
);

export const createReviewResponse = createSelector(
  createReviewState,
  (state) => state?.data
);
export const createReviewIsLoading = createSelector(
  createReviewStatus,
  (status) => status === Status.PENDING
);
export const createReviewHasError = createSelector(
  createReviewStatus,
  (status) => status === Status.ERROR
);

import { NgModule } from '@angular/core';
import {
  DefaultComponentModule,
  DefaultFormComponentModule,
} from '@innogy/core/modules/components';
import { EplusUiOnlyModule } from '@innogy/eplus-partners/ui-only';
import { UtilsEnvironmentModule } from '@innogy/utils/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CustomerSupportComponentEffects } from './+state/customer-support-component/customer-support.effects';
import {
  customerSupportReducer,
  customerSupportSelectorKey,
} from './+state/reducers';
import { CustomerSupportFormBodyComponent } from './customer-support-form-body/customer-support-form-body.component';
import { CustomerSupportFormComponent } from './customer-support-form/customer-support-form.component';
import { CustomerSupportSuccessComponent } from './customer-support-success/customer-support-success.component';
import { CustomerSupportVerificationComponent } from './customer-support-verification/customer-support-verification.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { EplusImageUploadModule } from './image-upload';

@NgModule({
  imports: [
    DefaultComponentModule,
    DefaultFormComponentModule,
    EffectsModule.forFeature([CustomerSupportComponentEffects]),
    StoreModule.forFeature(customerSupportSelectorKey, customerSupportReducer),
    EplusUiOnlyModule,
    EplusImageUploadModule,
    UtilsEnvironmentModule,
  ],
  declarations: [
    CustomerSupportFormComponent,
    CustomerSupportComponent,
    CustomerSupportVerificationComponent,
    CustomerSupportSuccessComponent,
    CustomerSupportFormBodyComponent,
  ],
  providers: [],
  exports: [CustomerSupportComponent],
})
export class EplusCustomerSupportFormModule {}

import type { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  createCustomerSupportRequest,
  createCustomerSupportRequestError,
  createCustomerSupportRequestSuccess,
} from '@innogy/eplus-partners/customer-support-form';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CustomerSupportService } from '../services/customer-support.service';

@Injectable()
export class CustomerSupportFormEffects {
  createCustomerSupportRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCustomerSupportRequest),
      switchMap((action) => {
        const { form, images } = action.payload;

        return this.customerSupportService.submit(form, images).pipe(
          map(() => createCustomerSupportRequestSuccess()),
          catchError((error: unknown) =>
            of(
              createCustomerSupportRequestError({
                payload: error as HttpErrorResponse,
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly customerSupportService: CustomerSupportService
  ) {}
}

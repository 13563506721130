import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import * as fromReviews from '../reducers';

export interface ReviewsState {
  readonly reviews: fromReviews.ReviewsState;
  readonly info: fromReviews.ReviewInfoState;
  readonly create: fromReviews.CreateReviewState;
}

export const reviewsStateSelectorKey = 'reviews';

export const getReviewsState = createFeatureSelector<ReviewsState>(
  reviewsStateSelectorKey
);

export const reviewsReducers: ActionReducerMap<ReviewsState, Action> = {
  reviews: fromReviews.GetReviewsReducer,
  info: fromReviews.ReviewInfoReducer,
  create: fromReviews.CreateReviewReducer,
};

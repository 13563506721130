import { createSelector } from '@ngrx/store';
import { Status } from '@essent/common';

import { getReviewsState } from './feature.selectors';

const getReviews = createSelector(
  getReviewsState,
  (feature) => feature?.reviews
);

export const getReviewsStatus = createSelector(
  getReviews,
  (state) => state?.status
);

export const getReviewsData = createSelector(
  getReviews,
  (state) => state?.data
);

export const getReviewsIsLoading = createSelector(
  getReviewsStatus,
  (status) => status === Status.PENDING || status === Status.IDLE
);

export const getReviewsHasError = createSelector(
  getReviewsStatus,
  (status) => status === Status.ERROR
);

import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { CreateTransactionState } from './create-transaction/create-transaction.reducer';
import { createTransactionReducer } from './create-transaction/create-transaction.reducer';
import type { GetIssuersState } from './get-issuers/get-issuers.reducer';
import { createGetIssuersReducer } from './get-issuers/get-issuers.reducer';
import type { PaymentFormState } from './payment-form/payment-form.reducer';
import { createPaymentFormReducer } from './payment-form/payment-form.reducer';
import type { UpdateStatusState } from './update-status/update-status.reducer';
import { createUpdateStatusReducer } from './update-status/update-status.reducer';

export interface PaymentState {
  readonly createTransaction: CreateTransactionState;
  readonly getIssuers: GetIssuersState;
  readonly updateStatus: UpdateStatusState;
  readonly paymentForm: PaymentFormState;
}

export const paymentReducerSelectorKey = 'payment';
export const getInvoiceState = createFeatureSelector<PaymentState>(
  paymentReducerSelectorKey
);
export const paymentReducer: ActionReducerMap<PaymentState, Action> = {
  createTransaction: createTransactionReducer,
  getIssuers: createGetIssuersReducer,
  updateStatus: createUpdateStatusReducer,
  paymentForm: createPaymentFormReducer,
};

import { Component, Input } from '@angular/core';

@Component({
  selector: 'wl-generic-offer-carousel-indicator',
  templateUrl: './generic-offer-carousel-indicator.component.html',
  styleUrls: [
    './generic-offer-carousel-indicator.component.ed.scss',
    './generic-offer-carousel-indicator.component.essent.scss',
  ],
})
export class GenericOfferCarouselIndicatorComponent {
  @Input({ required: true }) itemCount!: number;
  @Input({ required: true }) currentItemIndex!: number;

  itemIndexes(): number[] {
    return [...Array(this.itemCount).keys()];
  }
}

import { Component, Input } from '@angular/core';
import type { ToolTip } from '@innogy/common-ui/utility-components';
import type { ProductUsp } from '@innogy/common-ui/models';

@Component({
  selector: 'wl-generic-usps[usps]',
  templateUrl: './generic-usps.component.html',
  styleUrls: [],
})
export class GenericUspsComponent {
  @Input()
  usps!: ProductUsp[];

  tooltip(usp: ProductUsp): ToolTip {
    return {
      description: usp.tooltip,
    };
  }
}

import { Component, Input } from '@angular/core';
import type { ProductInfo } from '@innogy/common-ui/models';

@Component({
  selector: 'wl-generic-offer-info[productInfo]',
  templateUrl: './generic-offer-info.component.html',
  styleUrls: [
    './generic-offer-info.component.ed.scss',
    './generic-offer-info.component.essent.scss',
  ],
})
export class GenericOfferInfoComponent {
  @Input() marginTop = true;
  @Input() horizontalMargins = true;
  @Input() productInfo: ProductInfo[] = [];
}

import { createBaseAction } from '@essent/common';
import type { HttpErrorResponse } from '@angular/common/http';

import type { GetReviewsPayload, PaginatedReviews } from '../../interfaces';

export enum reviewsActionTypes {
  GET = '[Reviews] Get reviews',
  SUCCESS = '[Reviews] Get reviews success',
  ERROR = '[Reviews] Get reviews error',
  CLEAR = '[Reviews] clear',
}

export const getReviews = createBaseAction<GetReviewsPayload>(
  reviewsActionTypes.GET
);
export const getReviewsSuccess = createBaseAction<PaginatedReviews>(
  reviewsActionTypes.SUCCESS
);
export const getReviewsError = createBaseAction<HttpErrorResponse>(
  reviewsActionTypes.ERROR
);
export const getReviewsClear = createBaseAction<void>(reviewsActionTypes.CLEAR);

import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  nextIntakeStep,
  previousIntakeStep,
  resetIntakeForm,
  setIntakeStep,
} from './intake.actions';

export interface IntakeComponentState {
  page: number;
}

export const initialState: IntakeComponentState = {
  page: 1,
};

const _reducer = createReducer(
  initialState,
  on(nextIntakeStep, (state) => ({
    ...state,
    page: state.page + 1,
  })),
  on(previousIntakeStep, (state) => ({
    ...state,
    page: state.page - 1,
  })),
  on(setIntakeStep, (state, { payload }) => ({
    ...state,
    page: payload,
  })),
  on(resetIntakeForm, () => initialState)
);

export function intakeComponentReducer(
  state: IntakeComponentState = initialState,
  action: Action
): IntakeComponentState {
  return _reducer(state, action);
}

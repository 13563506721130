import { Component, Input } from '@angular/core';

import { GridItem } from './grid-item.model';

@Component({
  selector: 'ep-grid-item',
  templateUrl: './grid-item.component.html',
  styleUrls: ['./grid-item.component.ew.scss'],
})
export class GridItemComponent {
  @Input() item?: GridItem;
}

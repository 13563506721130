import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  previousIntakeStep,
  resetIntakeForm,
  setIntakeStep,
  submitIntakeForm,
} from './intake.actions';
import { resetBasicIntakeFormState } from '../basic-intake-form/basic-intake-form.actions';
import {
  createLead,
  createLeadClear,
  createLeadSuccess,
} from '../create-lead/create-lead.actions';

@Injectable()
export class IntakeComponentEffects {
  constructor(private readonly actions$: Actions) {}

  submitForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitIntakeForm),
      map(() => createLead())
    )
  );

  resetIntakeFormState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetIntakeForm),
      map((_) => resetBasicIntakeFormState())
    )
  );

  showFinalStepAfterLeadCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLeadSuccess),
      mergeMap((_) => [setIntakeStep({ payload: 3 })])
    )
  );

  clearCreateLeadStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(previousIntakeStep),
      map((_) => createLeadClear())
    )
  );
}

import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { InnogyComponentRendering } from '@innogy/jss/models';
import { getContentListValues } from '@innogy/jss/utils';

@Component({
  selector: 'ep-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.ew.scss'],
})
export class CallToActionComponent implements OnInit {
  @Input() rendering?: InnogyComponentRendering;

  additionalStylesBody?: string[];

  ngOnInit() {
    if (this.rendering && this.rendering.fields) {
      this.additionalStylesBody = getContentListValues(
        this.rendering.fields,
        'additionalStylesBody'
      );
    }
  }
}

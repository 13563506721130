import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./eplus-faq.lazy.module').then((mod) => mod.EplusFaqLazyModule);

const eplusFaqLazyImports: ComponentNameAndModule[] = [
  {
    path: 'FAQ',
    loadChildren: loadChildrenFunc,
  },
];
export { eplusFaqLazyImports };

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MarkAsTouchedAction } from 'ngrx-forms';
import { map, withLatestFrom } from 'rxjs/operators';

import {
  submitMoreInformationForm,
  validateMoreInformationForm,
} from './send-more-information.actions';
import { getSendMoreInformationState } from './send-more-information.selectors';

@Injectable()
export class SendMoreInformationEffects {
  validateMoreInformationForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateMoreInformationForm),
      withLatestFrom(
        this.store$.select(getSendMoreInformationState),
        (_, formState) => formState
      ),
      map((formState) => {
        if (formState.isInvalid) {
          return new MarkAsTouchedAction(formState.id);
        }

        return submitMoreInformationForm({
          email: formState.controls.email.value,
          mailingList: formState.controls.mailingList.value,
          approveTermsOfService: formState.controls.mandatoryCheckbox.value,
        });
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}
}

import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./appointment-confirmation.lazy.module').then(
    (mod) => mod.EplusEnergiewonenAppointmentConfirmationLazyModule
  );

const eplusEnergiewonenApointmentConfirmationLazyImports: ComponentNameAndModule[] =
  [
    {
      path: 'AppointmentConfirmation',
      loadChildren: loadChildrenFunc,
    },
  ];
export { eplusEnergiewonenApointmentConfirmationLazyImports };

import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./eplus-grid.lazy.module').then((mod) => mod.EplusGridLazyModule);

const eplusGridLazyImports: ComponentNameAndModule[] = [
  {
    path: 'SingleColumnRow',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'TwoColumnRow',
    loadChildren: loadChildrenFunc,
  },
  {
    path: 'ThreeColumnRow',
    loadChildren: loadChildrenFunc,
  },
];
export { eplusGridLazyImports };

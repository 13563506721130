import { Component, Input } from '@angular/core';
import { ProductScore } from '@innogy/common-ui/models';

@Component({
  selector: 'wl-generic-rating[rating]',
  templateUrl: './generic-rating.component.html',
  styleUrls: [
    './generic-rating.component.essent.scss',
    './generic-rating.component.ed.scss',
  ],
})
export class GenericRatingComponent {
  @Input()
  rating!: ProductScore;

  get filledCircles() {
    return Array.from({ length: this.rating.outOf5 }, (_, i) => i + 1);
  }

  get emptyCircles() {
    return Array.from({ length: 5 - this.rating.outOf5 }, (_, i) => i + 1);
  }
}

import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import type { GenericProductData } from '@innogy/common-ui/models';
import { MediaObserverService } from '@innogy/shared/responsive-tools';

@Component({
  selector: 'wl-generic-offer-container[products][listName]',
  templateUrl: './generic-offer-container.component.html',
  styleUrls: [
    './generic-offer-container.component.essent.scss',
    './generic-offer-container.component.ed.scss',
  ],
})
export class GenericOfferContainerComponent<
  TProduct extends GenericProductData
> {
  @Input()
  products!: TProduct[];

  @Input()
  listName!: string;

  @Output()
  productClicked = new EventEmitter<TProduct[]>();

  @ContentChild(TemplateRef)
  productTemplate?: TemplateRef<TProduct>;

  deviceInfo$ = this.mediaObserver.deviceInfo$;

  constructor(private readonly mediaObserver: MediaObserverService) {}

  hasReserveHighlightSpace() {
    return this.products.some((e) => e.content.highlight);
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_PREFIX } from '@innogy/config';

import type { CreateLeadPayload } from '../interfaces/create-lead-payload.interface';
import type { CreateLeadResponse } from '../interfaces/create-lead-response.interface';

@Injectable({
  providedIn: 'root',
})
export class CreateLeadService {
  private readonly endpoint = `${this.apiPrefix}/energiewonen/leads/`;

  constructor(
    private readonly http: HttpClient,
    @Inject(API_PREFIX) private readonly apiPrefix: string
  ) {}
  createLead(body: CreateLeadPayload) {
    return this.http.post<CreateLeadResponse>(this.endpoint, body);
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { ImageUploadState } from './image-upload.reducer';

export const imageUploadSelectorKey = 'imageUpload';
export const selectImageUploadState = createFeatureSelector<ImageUploadState>(
  imageUploadSelectorKey
);

export const selectImageUploadImages = createSelector(
  selectImageUploadState,
  (state) => state.files
);

export const selectImageUploadImageNames = createSelector(
  selectImageUploadImages,
  (files) => files.map((file) => file.name)
);

<div class="container">
  <div class="row">
    <div class="col">
      <div
        class="hero d-flex align-items-end"
        wlTrackImpression="contentblock-impression"
        [trackImpressionInfo]="rendering"
        wlAutomationId="hero"
      >
        <img
          wlAutomationId="heroBackgroundImage"
          *scImage="rendering?.fields?.backgroundImage"
          class="hero__image"
        />
        <div class="hero__image hero__image--overlay"></div>
        <div class="d-none d-lg-block col-6 ps-0">
          <div class="hero__panel-background bg-white px-5 pt-6 pb-4 pb-lg-6">
            <h1
              wlAutomationId="heroTitle"
              *scText="rendering?.fields?.title"
            ></h1>
            <wl-rich-text-wrapper
              [field]="rendering?.fields?.body"
              [rendering]="rendering"
            >
              <p></p>
            </wl-rich-text-wrapper>
            <a
              wlAutomationId="heroCtaLink"
              *wlGenericLink="rendering?.fields?.ctaButtonLink"
              class="btn btn-outline-primary"
              wlTrackImpression="button-impression"
              trackImpressionMode="button"
              [trackImpressionName]="rendering?.fields?.ctaButtonText?.value"
              [trackImpressionLinkurl]="
                rendering?.fields?.ctaButtonLink?.value.href
              "
              [trackImpressionInfo]="rendering"
              wlTrackLink
              [trackLinkInfo]="{
                rendering: rendering,
                field: rendering?.fields?.ctaButtonText?.value,
                eventType: 'button-click'
              }"
            >
              {{ rendering?.fields?.ctaButtonText?.value }}
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6 py-3 px-5">
          <sc-placeholder
            name="hero-items"
            class="hero__items d-flex align-items-end justify-content-end"
            [rendering]="rendering"
          ></sc-placeholder>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container d-lg-none" wlAutomationId="hero-xs">
  <div class="row py-md-4 py-2">
    <div class="col-12 col-sm-6">
      <h1 *scText="rendering?.fields?.title"></h1>
      <wl-rich-text-wrapper
        [field]="rendering?.fields?.body"
        [rendering]="rendering"
      >
        <p></p>
      </wl-rich-text-wrapper>
      <a
        *wlGenericLink="rendering?.fields?.ctaButtonLink"
        class="btn btn-outline-primary"
        wlTrackImpression="button-impression"
        trackImpressionMode="button"
        [trackImpressionName]="rendering?.fields?.ctaButtonText?.value"
        [trackImpressionLinkurl]="rendering?.fields?.ctaButtonLink?.value.href"
        [trackImpressionInfo]="rendering"
        wlTrackLink
        [trackLinkInfo]="{
          rendering: rendering,
          field: rendering?.fields?.ctaButtonText?.value,
          eventType: 'button-click'
        }"
      >
        {{ rendering?.fields?.ctaButtonText?.value }}
      </a>
    </div>
  </div>
</div>

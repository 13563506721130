import type { HttpErrorResponse } from '@angular/common/http';
import { Status } from '@essent/common';
import type { Action } from '@ngrx/store';
import { createReducer, on } from '@ngrx/store';

import {
  createLead,
  createLeadSuccess,
  createLeadClear,
  createLeadError,
} from './create-lead.actions';

export interface CreateLeadState {
  status: Status;
  error?: HttpErrorResponse;
}

const initialState: CreateLeadState = {
  status: Status.IDLE,
};

const _createLead = createReducer(
  initialState,
  on(createLead, (state) => ({ ...state, status: Status.PENDING })),
  on(createLeadSuccess, (state) => ({ ...state, status: Status.SUCCESS })),
  on(createLeadError, (state, { error }) => ({
    ...state,
    status: Status.ERROR,
    error,
  })),
  on(createLeadClear, (state) => ({ ...state, status: Status.IDLE }))
);

export function createLeadReducer(
  state: CreateLeadState = initialState,
  action: Action
): CreateLeadState {
  return _createLead(state, action);
}

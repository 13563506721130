import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { baseEffectFactory, HandleRequestService } from '@essent/common';
import { Actions, EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as reviewActions from './state/actions';
import { reviewsReducers, reviewsStateSelectorKey } from './state';
import { getReviewInfoEffectsConfig } from './state/effects';
import {
  CreateReviewService,
  ReviewsService,
  ReviewInfoService,
} from './state/services';

/* eslint-disable max-classes-per-file */
export class GetReviewsCommonEffects {}
export class GetReviewsEffectConfig {}
export function getReviewsConfigFactory() {
  return {
    requestAction: reviewActions.getReviews,
    successAction: reviewActions.getReviewsSuccess,
    errorAction: reviewActions.getReviewsError,
  };
}

export class GetReviewInfoCommonEffects {}
export class GetReviewInfoEffectConfig {}
export function getReviewInfoConfigFactory() {
  return getReviewInfoEffectsConfig;
}

export class CreateReviewCommonEffects {}
export class CreateReviewEffectConfig {}
export function CreateReviewConfigFactory() {
  return {
    requestAction: reviewActions.createReview,
    successAction: reviewActions.createReviewSuccess,
    errorAction: reviewActions.createReviewError,
  };
}

export const EFFECTS = [
  CreateReviewCommonEffects,
  GetReviewsCommonEffects,
  GetReviewInfoCommonEffects,
];
export const PROVIDERS = [
  CreateReviewService,
  ReviewsService,
  ReviewInfoService,
  {
    provide: GetReviewsEffectConfig,
    useFactory: getReviewsConfigFactory,
  },
  {
    provide: GetReviewsCommonEffects,
    useFactory: baseEffectFactory,
    deps: [
      Actions,
      HandleRequestService,
      ReviewsService,
      GetReviewsEffectConfig,
    ],
  },
  {
    provide: GetReviewInfoEffectConfig,
    useFactory: getReviewInfoConfigFactory,
  },
  {
    provide: GetReviewInfoCommonEffects,
    useFactory: baseEffectFactory,
    deps: [
      Actions,
      HandleRequestService,
      ReviewInfoService,
      GetReviewInfoEffectConfig,
    ],
  },
  {
    provide: CreateReviewEffectConfig,
    useFactory: CreateReviewConfigFactory,
  },
  {
    provide: CreateReviewCommonEffects,
    useFactory: baseEffectFactory,
    deps: [
      Actions,
      HandleRequestService,
      CreateReviewService,
      CreateReviewEffectConfig,
    ],
  },
];

@NgModule({
  providers: PROVIDERS,
  imports: [
    CommonModule,
    StoreModule.forFeature(reviewsStateSelectorKey, reviewsReducers),
    EffectsModule.forFeature(EFFECTS),
  ],
  declarations: [],
  exports: [],
})
export class EnergiewonenReviewsModule {}

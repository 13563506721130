import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { BaseService } from '@essent/common';
import { API_PREFIX } from '@innogy/config';

import type {
  UpdateStatusPayload,
  UpdateStatusResponse,
} from './update-status.interface';

@Injectable()
export class UpdateStatusService
  implements BaseService<UpdateStatusPayload, UpdateStatusResponse>
{
  apiVersion = '';
  endpoint = `${this.apiPrefix}/energiewonen/ideal/update-status/`;

  constructor(
    private readonly http: HttpClient,
    @Inject(API_PREFIX) private readonly apiPrefix: string
  ) {}
  call$(body: UpdateStatusPayload) {
    return this.http.post<UpdateStatusResponse>(this.endpoint, body);
  }
}

import { createBaseAction } from '@essent/common';
import type { HttpErrorResponse } from '@angular/common/http';
import { createAction } from '@ngrx/store';

import type { ReviewsInfo } from '../../interfaces';

export enum reviewInfoActionTypes {
  GET = '[Review Info] Get review info',
  GET_CACHED = '[Review Info] Get cached review info',
  SUCCESS = '[Review Info] Get review info success',
  ERROR = '[Review Info] Get review info error',
  CLEAR = '[Review Info] clear',
}

export const getReviewInfo = createBaseAction<void>(reviewInfoActionTypes.GET);
export const getReviewInfoCached = createAction(
  reviewInfoActionTypes.GET_CACHED
);
export const getReviewInfoSuccess = createBaseAction<ReviewsInfo>(
  reviewInfoActionTypes.SUCCESS
);
export const getReviewInfoError = createBaseAction<HttpErrorResponse>(
  reviewInfoActionTypes.ERROR
);
export const getReviewInfoClear = createBaseAction<void>(
  reviewInfoActionTypes.CLEAR
);

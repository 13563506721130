import type { ComponentNameAndModule } from '@sitecore-jss/sitecore-jss-angular';

const loadChildrenFunc = () =>
  import('./eplus-energiewonen-feedback.lazy.module').then(
    (mod) => mod.EplusEnergiewonenFeedbackLazyModule
  );

const eplusEnergiewonenFeedbackLazyImports: ComponentNameAndModule[] = [
  {
    path: 'Feedback',
    loadChildren: loadChildrenFunc,
  },
];
export { eplusEnergiewonenFeedbackLazyImports };

<div class="container">
  <div class="row row--intake-page">
    <div class="col-12 col-lg-6 pt-4 pt-lg-10 ps-0 pe-0 pe-lg-4">
      <ng-content select="[ep-form-page-titles]"></ng-content>
    </div>
    <div
      class="form-body col-12 col-lg-6 bg-white mt-4 mb-4"
      [ngClass]="{
        'p-0': !formBodyPadding,
        'p-2 p-lg-4 pt-lg-6 pb-6': formBodyPadding,
        'd-none d-lg-block': !showPageFormOnSmallScreen
      }"
    >
      <ng-content select="[ep-form-page-form]"></ng-content>
    </div>
  </div>
</div>

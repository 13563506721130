import { DOCUMENT } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Inject, Input, Renderer2 } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@innogy/config';
import { EnvironmentConfig } from '@innogy/config/models';

@Component({
  selector: 'ep-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.ew.scss'],
})
export class FormPageComponent implements OnInit, OnDestroy {
  @Input() formBodyPadding = true;
  @Input() showPageFormOnSmallScreen = true;

  constructor(
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ENVIRONMENT_CONFIG) private readonly config: EnvironmentConfig
  ) {}

  ngOnInit() {
    // Its not pretty, ViewEncapsulation.none is not working with apply-brand-host-context, this is the workaround
    this.renderer.addClass(
      this.document.body,
      this.getBodyClassByAppName(this.config.app)
    );
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      this.document.body,
      this.getBodyClassByAppName(this.config.app)
    );
  }

  getBodyClassByAppName(appName: string): string {
    switch (appName) {
      case 'energiewonen':
        return 'bg-sand';
      default:
        return 'bg';
    }
  }
}

import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';
import type { Action } from '@ngrx/store';

import {
  CreateTransactionAction,
  CreateTransactionClearAction,
  CreateTransactionErrorAction,
  CreateTransactionSuccessAction,
} from './create-transaction.actions';
import type { CreateTransactionResponse } from './create-transaction.interface';

export type CreateTransactionState = AsyncState<CreateTransactionResponse>;

const _createTransaction = createAsyncStateReducer(
  CreateTransactionAction,
  CreateTransactionSuccessAction,
  CreateTransactionErrorAction,
  CreateTransactionClearAction
);

export function createTransactionReducer(
  state: CreateTransactionState = { status: Status.IDLE },
  action: Action
): CreateTransactionState {
  return _createTransaction(state, action);
}

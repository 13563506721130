import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';
import type { Action } from '@ngrx/store';

import {
  getIssuersAction,
  getIssuersClearAction,
  getIssuersErrorAction,
  getIssuersSuccessAction,
} from './get-issuers.actions';
import type { GetIssuersResponse } from './get-issuers.interface';

export type GetIssuersState = AsyncState<GetIssuersResponse>;

const _getIssuers = createAsyncStateReducer(
  getIssuersAction,
  getIssuersSuccessAction,
  getIssuersErrorAction,
  getIssuersClearAction
);

export function createGetIssuersReducer(
  state: GetIssuersState = { status: Status.IDLE },
  action: Action
): GetIssuersState {
  return _getIssuers(state, action);
}

import type { Action, ActionReducerMap } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';

import type { CreateCustomerSupportRequestState } from './create-customer-support-request/create-customer-support-request.reducer';
import { createCustomerSupportRequestReducer } from './create-customer-support-request/create-customer-support-request.reducer';
import type { CustomerSupportFormState } from './customer-support-form/customer-support-form.reducer';
import { customerSupportFormReducer } from './customer-support-form/customer-support-form.reducer';
import type { CustomerSupportComponentState } from './customer-support-component/customer-support.reducer';
import { customerSupportComponentReducer } from './customer-support-component/customer-support.reducer';

export interface CustomerSupportState {
  readonly create: CreateCustomerSupportRequestState;
  readonly customerSupportForm: CustomerSupportFormState;
  readonly customerSupportComponent: CustomerSupportComponentState;
}

export const customerSupportSelectorKey = 'customerSupport';
export const getCustomerSupportSelector =
  createFeatureSelector<CustomerSupportState>(customerSupportSelectorKey);
export const customerSupportReducer: ActionReducerMap<
  CustomerSupportState,
  Action
> = {
  create: createCustomerSupportRequestReducer,
  customerSupportForm: customerSupportFormReducer,
  customerSupportComponent: customerSupportComponentReducer,
};

import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { CreateLeadService } from '@innogy/eplus-partners/energiewonen/twosolar';
import type { HttpErrorResponse } from '@angular/common/http';

import {
  createLeadSuccess,
  createLeadError,
  createLead,
} from './create-lead/create-lead.actions';
import { selectCreateLeadPayload } from './intake.selectors';

@Injectable()
export class IntakeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>,
    private readonly createLeadService: CreateLeadService
  ) {}

  createLeadPayload$ = this.store$.select(selectCreateLeadPayload);

  createIntakeLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLead),
      concatLatestFrom(() => this.createLeadPayload$),
      mergeMap(([_, payload]) =>
        this.createLeadService.createLead(payload).pipe(
          map(() => createLeadSuccess()),
          catchError((e: unknown) =>
            of(createLeadError({ error: e as HttpErrorResponse }))
          )
        )
      )
    )
  );
}

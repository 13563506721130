import { Component, Input } from '@angular/core';

@Component({
  selector: 'ep-column-card',
  template: `
    <div [ngClass]="background" class="p-2">
      <ng-content></ng-content>
    </div>
  `,
})
export class ColumnCardComponent {
  @Input() background?: string;
}

<img
  [ngClass]="{
    'image--fixed-height': rendering?.fields?.fixedHeight?.value,
    'image--full-width': rendering?.fields?.fullWidth?.value,
    'image--brand': rendering?.fields?.applyBrandStyling?.value
  }"
  *scImage="rendering?.fields?.image?.value | imagePlaceholder"
  wlAutomationId="image"
  loading="lazy"
/>

import { Component, Input } from '@angular/core';

import type { GridItem } from '../grid-item/grid-item.model';

@Component({
  selector: 'ep-grid-overview',
  templateUrl: './grid-overview.component.html',
  styleUrls: [],
})
export class GridOverviewComponent {
  public colConfig = ['col-12', 'col-sm-6', 'col-md-4'];
  @Input() public items?: GridItem[];
}

<div
  class="flex-grow-1 card offer-card"
  [class.offer-card--outlined]="selected"
>
  <div
    *ngIf="isHighlighted"
    wlAutomationId="genericOffer.highlight"
    class="highlight rounded-top p-2 text-white"
    wlCSCapture
  >
    <span>{{ product.content.highlight }}</span>
  </div>
  <div
    class="flex-1 d-flex flex-column align-items-center card-header__product p-4"
    [class.card-header__product--selectable]="isSelectable"
    [class.rounded-top]="!isHighlighted"
  >
    <div class="card-header__title text-center" wlCSCapture>
      {{ product.content.title }}
    </div>
    <div
      class="card-header__subtitle text-center"
      *ngIf="product.content.subTitle"
      wlCSCapture
    >
      {{ product.content.subTitle }}
    </div>
  </div>

  <div
    class="flex-1 d-flex flex-column flex-grow-1 rounded-bottom card-header__content"
    [ngClass]="{ 'border-brand': selected }"
  >
    <div
      class="p-3 offer-card__section"
      *ngIf="product.content.scores?.length > 0"
    >
      <wl-generic-rating
        *ngFor="let score of product.content.scores"
        [rating]="score"
      ></wl-generic-rating>
    </div>

    <div
      *ngIf="product.content.image?.src"
      wlAutomationId="genericOffer.image"
      class="image-wrapper offer-card__section p-2 d-flex justify-content-center align-items-center"
    >
      <img
        [src]="product.content.image.src"
        [alt]="product.content.image.alt"
      />
    </div>

    <ng-content select="[top]"></ng-content>

    <wl-generic-offer-info
      [productInfo]="product.content.productInfo"
    ></wl-generic-offer-info>

    <div class="d-flex flex-grow-1 flex-column">
      <wl-generic-usps
        *ngIf="product.content.usps?.length > 0"
        [usps]="product.content.usps"
        class="p-2"
      ></wl-generic-usps>

      <wl-rich-text-wrapper
        *ngIf="product.content.text as description"
        [field]="{ value: description }"
        [rendering]="rendering"
      >
        <div class="px-2" [class.pt-2]="!product.content.usps?.length"></div>
      </wl-rich-text-wrapper>
    </div>

    <ng-content select="[bottom]"></ng-content>

    <div class="flex-1 p-2" *ngIf="buttonTextField?.value">
      <button
        type="button"
        class="btn btn-sales-primary w-100"
        (click)="onClicked()"
        *scText="buttonTextField"
        [wlAutomationId]="product.content.title + '.purchase-button'"
        wlTrackLink
        [trackLinkInfo]="{
          rendering: rendering,
          field: buttonTextField,
          linkurl: buttonLinkUrl,
          type: 'button',
          eventType: 'button-click'
        }"
      ></button>
    </div>
    <div class="flex-1 px-2 pb-2" *ngIf="product.content.secondaryButtonText">
      <button
        type="button"
        [ngClass]="['w-100', product.content.secondaryButtonStyle]"
        (click)="onSecondaryClicked()"
        *scText="{ value: product.content.secondaryButtonText }"
        [wlAutomationId]="product.content.title + '.secondary-button'"
        wlTrackLink
        [trackLinkInfo]="{
          rendering: rendering,
          field: product.content.secondaryButtonText,
          linkurl: secondaryButtonLinkUrl,
          type: 'button',
          eventType: 'button-click'
        }"
      ></button>
    </div>
  </div>
</div>

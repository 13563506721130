<ul class="fa-ul mb-0 ms-3 usp-block" wlCSCapture>
  <li *ngFor="let usp of usps">
    <fa-icon
      class="fa-li text-success"
      [class.text-muted]="usp.inactive"
      [icon]="usp.inactive ? 'times' : 'check'"
    ></fa-icon>
    <span [class.text-muted]="usp.inactive" class="me-1">{{ usp.text }}</span>
    <wl-tooltip *ngIf="usp.tooltip" [tooltip]="tooltip(usp)"></wl-tooltip>
  </li>
</ul>

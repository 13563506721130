import { filter, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import {
  resetCustomerSupportForm,
  setCustomerSupportStep,
  setShowNoPhotosWarning,
  submitCustomerSupportForm,
  successfullySubmittedCustomerSupportForm,
} from './customer-support.actions';
import { selectCustomerSupportComponentState } from './customer-support.selectors';
import { resetCustomerSupportFormState } from '../customer-support-form/customer-support-form.actions';
import {
  addFiles,
  resetImageUpload,
} from '../../image-upload/+state/image-upload.actions';
import { selectImageUploadImages } from '../../image-upload/+state/image-upload.selectors';
import { getCustomerSupportFormState } from '../customer-support-form/customer-support-form.selectors';
import {
  createCustomerSupportRequest,
  createCustomerSupportRequestClear,
  createCustomerSupportRequestSuccess,
} from '../create-customer-support-request/create-customer-support-request.actions';

@Injectable()
export class CustomerSupportComponentEffects {
  private readonly selectImageUploadImages$ = this.store$.pipe(
    select(selectImageUploadImages)
  );

  private readonly getCustomerSupportFormState$ = this.store$.pipe(
    select(getCustomerSupportFormState)
  );

  private readonly getCustomerSupportComponentState$ = this.store$.pipe(
    select(selectCustomerSupportComponentState)
  );

  createCustomerSupportFormRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitCustomerSupportForm),
      concatLatestFrom(() => [
        this.getCustomerSupportComponentState$,
        this.getCustomerSupportFormState$,
        this.selectImageUploadImages$,
      ]),
      map(([_, component, form, images]) => {
        if (images.length === 0 && component.showNoPhotosWarning === false) {
          return setShowNoPhotosWarning({ payload: true });
        }

        return createCustomerSupportRequest({
          payload: { form: form.value, images },
        });
      })
    )
  );

  hidePhotosWarningOnFileUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFiles),
      concatLatestFrom(() => this.getCustomerSupportComponentState$),
      filter(([_, component]) => component.showNoPhotosWarning),
      map(() => setShowNoPhotosWarning({ payload: false }))
    )
  );

  createCustomerSupportRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCustomerSupportRequestSuccess),
      map(() => successfullySubmittedCustomerSupportForm())
    )
  );

  resetCustomerSupportFormState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetCustomerSupportForm),
      mergeMap((_) => [
        resetCustomerSupportFormState(),
        resetImageUpload(),
        createCustomerSupportRequestClear(),
      ])
    )
  );

  successfullySubmittedCustomerSupportForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(successfullySubmittedCustomerSupportForm),
      map((_) => setCustomerSupportStep({ payload: 3 }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<any>
  ) {}
}

import type { AsyncState } from '@essent/common';
import { createAsyncStateReducer, Status } from '@essent/common';
import type { Action } from '@ngrx/store';

import type { CreateReviewResponse } from '../../interfaces';
import * as actions from '../actions';

export type CreateReviewState = AsyncState<CreateReviewResponse>;

const initalState: CreateReviewState = { status: Status.IDLE };
const _createReview = createAsyncStateReducer(
  actions.createReview,
  actions.createReviewSuccess,
  actions.createReviewError,
  actions.createReviewClear
);

export function CreateReviewReducer(
  state: CreateReviewState = initalState,
  action: Action
): CreateReviewState {
  return _createReview(state, action);
}

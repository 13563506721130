import { createNamedApi } from '@essent/common';

import { CreateTransactionService } from './create-transaction.service';

export const createTransactionApi = createNamedApi(
  '[Payment] Create Transaction',
  CreateTransactionService
);

export const {
  createTransaction: CreateTransactionAction,
  createTransactionSuccess: CreateTransactionSuccessAction,
  createTransactionError: CreateTransactionErrorAction,
  createTransactionClear: CreateTransactionClearAction,
  createTransactionActions,
} = createTransactionApi;
